import { useState, useEffect } from "react";
import banner1 from "../../../../assetss/BannerCarousel/banner-1.png";
import banner2 from "../../../../assetss/BannerCarousel/banner-2.png";
import banner3 from "../../../../assetss/BannerCarousel/banner-3.png";
import mobileBanner1 from "../../../../assetss/BannerCarousel/mobile-banner-1.png";
import mobileBanner2 from "../../../../assetss/BannerCarousel/mobile-banner-2.png";
import mobileBanner3 from "../../../../assetss/BannerCarousel/mobile-banner-3.png";
import mobileBanner4 from "../../../../assetss/BannerCarousel/mobile-banner-4.png";
import mobileBanner5 from "../../../../assetss/BannerCarousel/mobile-banner-5.png";
import mobileBanner6 from "../../../../assetss/BannerCarousel/mobile-banner-6.png";
import mobileBanner7 from "../../../../assetss/BannerCarousel/mobile-banner-7.png";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const BannerCarousel = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [currentMobileImage, setCurrentMobileImage] = useState(0); // Separate state for mobile images

  const desktopImages = [banner1, banner2, banner3];
  const mobileImages = [
    mobileBanner1,
    mobileBanner2,
    mobileBanner3,
    mobileBanner4,
    mobileBanner5,
    mobileBanner6,
    mobileBanner7,
  ];

  // Functions to go to the next image
  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % desktopImages.length);
  };
  const nextMobileImage = () => {
    setCurrentMobileImage((prev) => (prev + 1) % mobileImages.length); // Use mobile state for mobile carousel
  };

  // Functions to go to the previous image
  const prevImage = () => {
    setCurrentImage((prev) =>
      prev === 0 ? desktopImages.length - 1 : prev - 1
    );
  };
  const prevMobileImage = () => {
    setCurrentMobileImage((prev) =>
      prev === 0 ? mobileImages.length - 1 : prev - 1
    );
  };

  // Auto transition for desktop images
  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 5000); // Automatically transition images every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Auto transition for mobile images
  useEffect(() => {
    const interval = setInterval(() => {
      nextMobileImage();
    }, 5000); // Automatically transition images every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <div>
      {/* Desktop view */}
      <div className="hidden lg:block">
        <div className=" overflow-hidden mx-auto flex flex-col justify-center">
          <div className="relative w-full bg-gray-200">
            {/* Arrows for navigation */}
            <div className="absolute inset-0 flex items-center justify-between pointer-events-none">
              <div className="flex justify-between w-full">
                <button
                  onClick={prevImage}
                  className="bg-white shadow-md rounded-full p-2 pointer-events-auto border-none cursor-pointer ml-2"
                >
                  <FaArrowLeft />
                </button>
                <button
                  onClick={nextImage}
                  className="bg-white shadow-md rounded-full p-2 pointer-events-auto border-none cursor-pointer mr-2"
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>

            {/* Image Carousel */}
            <div className="flex w-full overflow-hidden">
              <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{
                  transform: `translateX(-${currentImage * 100}%)`,
                  width: `${desktopImages.length * 100}%`,
                }}
              >
                {desktopImages.map((imageUrl, index) => (
                  <div className="w-full" key={index} style={{ flex: "0 0 100%" }}>
                    <img
                      src={imageUrl}
                      alt={`image${index + 1}`}
                      className="w-full max-w-[full]  h-[auto] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Dots for navigation */}
            <div className="absolute bottom-4 left-0 right-0 z-20 flex justify-center gap-2">
              {desktopImages.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full ${currentImage === index ? "bg-white" : "bg-gray-400"
                    } cursor-pointer`}
                  onClick={() => setCurrentImage(index)}
                ></button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view */}
      <div className="lg:hidden">
        <div className="overflow-hidden max-w-full mx-auto flex flex-col justify-center">
          <div className="relative w-full bg-gray-200">
            {/* Arrows for navigation */}
            <div className="absolute inset-0 flex items-center justify-between pointer-events-none">
              {/* <div className="flex justify-between w-full">
                <button
                  onClick={prevMobileImage}
                  className="bg-white shadow-md rounded-full p-2 pointer-events-auto border-none cursor-pointer ml-2"
                >
                  <FaArrowLeft />
                </button>
                <button
                  onClick={nextMobileImage}
                  className="bg-white shadow-md rounded-full p-2 pointer-events-auto border-none cursor-pointer mr-2"
                >
                  <FaArrowRight />
                </button>
              </div> */}
            </div>

            {/* Mobile Image Carousel */}
            <div className="flex w-full  overflow-hidden">
              <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{
                  transform: `translateX(-${currentMobileImage * 100}%)`, // Use currentMobileImage for mobile
                  width: `${mobileImages.length * 100}%`,
                }}
              >
                {mobileImages.map((imageUrl, index) => (
                  <div className="w-full " key={index} style={{ flex: "0 0 100%" }}>
                    <img
                      src={imageUrl}
                      alt={`mobile-image${index + 1}`}
                      className="w-full object-cover h-[330px]"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Dots for mobile navigation */}
            <div className="absolute bottom-4 left-0 right-0 z-20 flex justify-center gap-2">
              {mobileImages.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full ${currentMobileImage === index ? "bg-white" : "bg-gray-400"
                    } cursor-pointer`}
                  onClick={() => setCurrentMobileImage(index)} // Use setCurrentMobileImage for mobile dots
                ></button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCarousel;
