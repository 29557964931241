import React from 'react';
import Header from '../SubComponents/Header/Header';
import LocalCoordinationBanner from './subComponents/LocalCoordinationBanner/LocalCoordinationBanner';
import WhyLocalCoordination from './subComponents/WhyLocalCoordination/WhyLocalCoordination';
import OurProcess from './subComponents/OurProcess/OurProcess';

const LocalCoordination = () => {

  return (
    <div>
      
      <LocalCoordinationBanner/>
      <WhyLocalCoordination/>
      <OurProcess/>
  </div>
  )
}

export default LocalCoordination
