import React from 'react';
import icon1 from '../../../../../assetss/afterSalesSupport/icons/Bright Mind.png';
import icon2 from '../../../../../assetss/afterSalesSupport/icons/Performance Macbook.png';
import icon3 from '../../../../../assetss/afterSalesSupport/icons/Handshake.png';


const BenifitsAfterSales = () => {
  return (
    <div>
      <div className="benifits-after-sales flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w[1279px] lg:w-[full] py-[30px]'>
            <div className='container mx-auto'>
            <h1 className='text-center text-3xl font-bold'>Benefits of Our After-Sales Support</h1>
            <div className='flex flex-wrap lg:flex-nowrap gap-[20px] py-[20px]'>
                <div className='lg:w-1/3 md:w-1/3  sm:w-full w-full p-[20px] '>
                <div className='flex gap-[10px] border border-[#0C92CC] rounded-lg p-3'>
                    <img src={icon1} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl font-semibold'>Peace Of Mind</h1>
                        <p>Enjoy the confidence that comes with knowing help is just a call away</p>
                    </div>
                </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3  sm:w-full w-full p-[20px]'>
                <div className='flex gap-[10px] border border-[#0C92CC] rounded-lg p-3'>
                    <img src={icon2} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl font-semibold'>Peace Of Mind</h1>
                        <p>Enjoy the confidence that comes with knowing help is just a call away</p>
                    </div>
                </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3  sm:w-full w-full  p-[20px]'>
                <div className='flex gap-[10px] border border-[#0C92CC] rounded-lg p-3'>
                    <img src={icon3} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl font-semibold'>Peace Of Mind</h1>
                        <p>Enjoy the confidence that comes with knowing help is just a call away</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default BenifitsAfterSales
