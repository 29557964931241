import React from 'react';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
 import vector1 from '../../../../../assetss/paymentFollowUp/vector3.png'

const DocumentationReport = () => {
  return (
    <div>
      <div className="documentation-report flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] py-[20px] '>
            <h1 className='text-3xl  font-bold text-center'>Documentation Report</h1>
            <div className='flex flex-wrap lg:flex-nowrap py-[20px]'>
                <div className='lg:w-3/5 md:w-3/5 sm:w-full w-full flex items-center'>
                <div className='flex flex-col gap-[10px]'>
                    <div className='flex gap-[10px]'>
                        <div className='flex items-center justify-center' >
                            <IoMdCheckmarkCircleOutline className='text-6xl'/>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>Comprehensive Paperwork</h1>
                            <p>We assist you with all necessary documentation to facilitate smooth customs clearance and compliance with regulations.</p>
                        </div>
                    </div>
                    <div className='flex gap-[10px]'>
                        <div className='flex items-center justify-center' >
                            <IoMdCheckmarkCircleOutline className='text-6xl'/>
                        </div>
                        <div>
                            <h1 className='text-xl font-semibold'>Comprehensive Paperwork</h1>
                            <p>We assist you with all necessary documentation to facilitate smooth customs clearance and compliance with regulations.</p>
                        </div>
                    </div>
                </div>
              
                </div>
                <div className='lg:w-2/5 md:w-2/5 sm:w-full w-full'>
                    <img src={vector1} alt=""  className='w-[full] h-[auto]'/>
                </div>
            </div>
            <div className='p-[20px]'>
                <p className='text-center'>Your satisfaction is our priority at ZMM, we are dedicated to ensuring your satisfaction through effective payment follow up andrigorous quality assurance, our goal is to foster long term relationships built on trust and reliability.</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentationReport
