import React from 'react'
import Header from '../SubComponents/Header/Header'
import LocalNeedsBanner from './SubComponents/LocalNeedsBanner/LocalNeedsBanner'
import WhyChooseLocal from './SubComponents/WhyChooseLocal/WhyChooseLocal'
import OurServices from './SubComponents/OurServices/OurServices'
import BenifitsOfServices from './SubComponents/BenifitsOfServices/BenifitsOfServices'
import WhyChooseNeeds from './SubComponents/WhyChooseNeeds/WhyChooseNeeds'

const LocalNeeds = () => {
  return (
    <div>
      
      <LocalNeedsBanner/>
      <WhyChooseLocal/>
      <OurServices/>
      <BenifitsOfServices/>
      <WhyChooseNeeds/>
    </div>
  )
}

export default LocalNeeds
