import React from 'react'

const ZmmDirService = () => {
  return (
    <>
    <div className='flex justify-center px-3 '>
    <div className='mt-5 max-w-[1400px] bg-white'>
        <div className='mb-10'>
          <h5 className='text-blood text-center text-3xl font-semibold'>Welcome to ZeroMiddleman Directory Service</h5>
        </div>
        <div className='lg:flex lg:justify-around p-3 md:p-16 mx-1 lg:mx-2 my-1 rounded-2xl md:border md:shadow-lg shadow-gray-200 hover:shadow-orange-700 hover:shadow-lg'>
          
          {/* Image */}
          <div className='mb-5 lg:mb-0 overflow-hidden rounded-2xl'>
            <img 
              className='rounded-2xl transform transition-transform duration-500 hover:scale-105 hover:rounded-2xl' 
              src={require("../assetss/ZmmDirectoryPage/CuteGirl.png")} 
              alt="pretty girl" 
            />
          </div>

          {/* Paragraph Content */}
          <div className='w-full lg:w-1/2'>
            <h3 className='text-xl font-bold'>Welcome to Zmm</h3>
            <p className='text-sm pb-5'>Your premier destination for comprehensive contact directory services.</p>
            <p className='text-[14px] text-justify'>We specialize in connecting you to the right people, businesses, and opportunities with ease and precision. Whether you're looking to expand your network, grow your business, or simply find the information you need, we are here to help. Our extensive database is meticulously curated to ensure you have access to accurate and up-to-date contact details across a wide range of industries and sectors. We understand the value of reliable connections, which is why we are committed to providing a platform that is not only easy to navigate but also packed with the resources you need to succeed. We believe that effective communication is the cornerstone of success. Our mission is to empower individuals and businesses by providing them with the tools and information necessary to build meaningful relationships and foster growth. Whether you're a small business owner, a sales professional, or someone looking to connect, we've got you covered.</p>
          </div>
        </div>
      </div>
    </div>
      
    </>
  )
}

export default ZmmDirService
