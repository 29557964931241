import React, { useState } from "react";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";

const SuccessStory = () => {
  // Array of success stories
  const successStories = [
    {
      name: "TL Faizel",
      company: "Emayam Tech",
      message:
        "I feel on top of the world. I feel incredible, I feel motivated, I feel empowered. I’m the master of my own destiny.",
      image: require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png"),
    },
    {
      name: "Jane Doe",
      company: "Tech Innovators",
      message:
        "I feel grateful for the support. This journey has been nothing short of amazing!",
      image: require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png"),
    },
    {
      name: "John Smith",
      company: "Startup Hub",
      message:
        "The best service I have experienced in my career journey. Highly recommend it!",
      image: require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png"),
    },
    {
      name: "Samantha Lee",
      company: "FutureTech Solutions",
      message:
        "The entire process was smooth, and I’ve achieved great success with their help!",
      image: require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png"),
    },
    {
      name: "Michael Doe",
      company: "Global Ventures",
      message:
        "A game-changing experience. They helped me scale my business to new heights.",
      image: require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png"),
    },
    {
      name: "Alice Brown",
      company: "TechSphere",
      message:
        "The perfect service for anyone looking to grow. I’m incredibly thankful!",
      image: require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png"),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0); // To keep track of the current visible set

  // Function to go to the next set of 3 cards
  const handleNext = () => {
    if (currentIndex + 3 < successStories.length) {
      setCurrentIndex(currentIndex + 3);
    }
  };

  // Function to go to the previous set of 3 cards
  const handlePrev = () => {
    if (currentIndex - 3 >= 0) {
      setCurrentIndex(currentIndex - 3);
    }
  };

  // Determine the cards to be shown based on currentIndex
  const visibleStories = successStories.slice(currentIndex, currentIndex + 3);

  return (
    <>
      <div className="flex justify-center bg-white max-w-[1400px] px-5 mx-auto md:relative">
        <div className="">
        <div className="mb-16 mx-auto ">
        <div className="text-center mt-5">
          <h1 className="text-blood text-3xl font-semibold">Success Story</h1>
          <p className="text-lg mt-2">What customers say about us!</p>
        </div>

        {/* Carousel Wrapper */}
        <div className="  mx-auto">
          <div className="hidden md:flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-28 mx-auto mt-10">
              {visibleStories.map((story, index) => (
                <div
                  key={index}
                  className="w-[330px] h-[300px] border-[3px] border-gray-200 rounded-3xl py-10 px-7 space-y-1 hover:shadow-lg orange-700 transition-shadow duration-300 ease-in-out"
                >
                  <img
                    className="mx-auto h-[90px] w-[90px] rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300"
                    src={story.image}
                    alt={story.name}
                  />
                  <h1 className="text-center text-xl font-bold text-blood">
                    {story.name}
                  </h1>
                  <h1 className="text-center text-lg">{story.company}</h1>
                  <p className="text-center pb-10 font-medium text-sm">
                    {story.message}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Carousel Controls for md and larger */}
          <div className="hidden md:flex justify-between mt-6">
            <button
              onClick={handlePrev}
              className={`absolute left-2 top-[50%] text-4xl ${
                currentIndex === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={currentIndex === 0}
            >
              <FaArrowCircleLeft />
            </button>

            <button
              onClick={handleNext}
              className={`absolute top-[50%] right-2 text-4xl ${
                currentIndex + 3 >= successStories.length
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={currentIndex + 3 >= successStories.length}
            >
              <FaArrowCircleRight />
            </button>
          </div>

          {/* Horizontal Scroll for mobile and tablet view */}
          <div className="flex md:hidden flex flex-col items-center gap-6 mt-6">
            {successStories.map((story, index) => (
              <div
                key={index}
                className="min-w-[130px] h-[300px] flex-shrink-0 border-[3px] border-gray-200  w-4/5 rounded-3xl py-10 px-7 mx-3 space-y-1 hover:shadow-lg orange-700 transition-shadow duration-300 ease-in-out"
              >
                <img
                  className="mx-auto h-[90px] w-[90px] rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300"
                  src={story.image}
                  alt={story.name}
                />
                <h1 className="text-center text-xl font-bold text-blood">
                  {story.name}
                </h1>
                <h1 className="text-center text-lg">{story.company}</h1>
                <p className=" text-center pb-10 font-medium text-sm">
                  {story.message}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
        </div>
      </div>
      
    </>
  );
};

export default SuccessStory;
