import React from "react";
import "./Keyfeatures.css";
import gif from "../assetss/dirGIF/hard-drive.gif";
import gif2 from "../assetss/dirGIF/dashboard.gif";
import gif3 from "../assetss/dirGIF/credit-card.gif";
import gif4 from "../assetss/dirGIF/report.gif";
import gif5 from "../assetss/dirGIF/people-contact.gif";
import gif6 from "../assetss/dirGIF/easy.gif";
import gif7 from "../assetss/dirGIF/best-seller.gif";
import gif8 from "../assetss/dirGIF/interface.gif";
import gif9 from "../assetss/dirGIF/helpdesk.gif";
import gif10 from "../assetss/dirGIF/copywriting.gif";
import gif11 from "../assetss/dirGIF/target.gif";
// import gif10 from "../assetss/dirGIF/copywriting.gif";

const Keyfeatures = () => {
  // Example data for cards
  const cards = [
    {         
      img: gif,
      title: "Comprehensive Database",
      description: "Access a vast collection of contact information",
    },
    {
      img: gif2,
      title: "Your Dashboard",
      description: "Connect with businesses across the globe",
    },
    {
      img: gif3,
      title: "Reduce Risk",
      description: "Connect with businesses across the globe",
    },
    {
      img: gif4,
      title: "Real - Time Updates and Alerts ",
      description: "Ensure accurate and reliable information",
    },
    {
      img: gif5,
      title: "Global Contacts",
      description: "Find contact info in seconds",
    },
    {
      img: gif6,
      title: "Easy Navigation ",
      description: "Access a vast collection of contact information",
    },
    {
      img: gif7,
      title: "Value - Driven Pricing ",
      description: "Connect with businesses across the globe",
    },
    {
      img: gif8,
      title: "User - friendly Interface ",
      description: "Ensure accurate and reliable information",
    },
    {
      img: gif9,
      title: "Customer Support ",
      description: "Find contact info in seconds",
    },
    {
      img: gif10,
      title: "Advanced Search Filters ",
      description: "Access a vast collection of contact information",
    },
    {
      img: gif11,
      title: "Focus Martkets",
      description: "Connect with businesses across the globe",
    },
    {
      img: gif11,
      title: "Verified Listings",
      description: "Ensure accurate and reliable information",
    },
  ];

  return (
    <>
      <div className="mx-auto max-w-[1400px] px-5 bg-white">
        <div className="mx-auto">
          <h1 className="text-center text-blood font-semibold text-3xl p-5">
            Key Features
          </h1>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-between gap-y-11 gap-x-10 pb-2 ">
          {cards.map((card, index) => (
            <div
              key={index}
              className="mx-auto relative w-[275px] h-[175px] border border-gray-400 rounded-2xl flex justify-center items-end overflow-hidden hover-box"
            >
              <img
                src={card.img}
                alt={card.title}
                className="w-[50px] h-[50px] my-3 mb-5 object-cover absolute bottom-[80px] opacity-100 transition-all duration-7000 ease-in hover-box-text"
              />
              <h1 className="my-1 text-lg font-semibold text-blood absolute bottom-[50px] opacity-100 transition-all duration-7000 ease-in h-5 hover-box-text">
                {card.title}
              </h1>
              <h1 className="px-3 text-center absolute bottom-[1px] opacity-100 transition-all duration-700 ease-in-out h-0 hover-box-text ">
                {card.description}
              </h1>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Keyfeatures;
