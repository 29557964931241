


import React, { useState, useEffect } from "react";
import { PiShieldCheck } from "react-icons/pi";

const products = [
  {
      id: 1,
      title: "Brand NEW Fiber Helmet",
      discount: "Up to 30% off",
      price: "$2,000 INR",
      oldPrice: "$3000",
      description:
        "Electronics is the study and use of electrical components like transistors.",
        sellerName: "TL Faizal",
      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 2,
      title: "Brand NEW Headset",
      discount: "Up to 20% off",
      price: "$1,200 INR",
      oldPrice: "$1500",
      description: "Audio equipment designed for immersive sound experience....",
      sellerName: "Abubakar",

      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 3,
      title: " Bluetooth Speaker",
      discount: "Up to 25% off",
      price: "$800 INR",
      oldPrice: "$1000",
      description: "Compact speaker with high-quality sound output....",
      sellerName: "JP",

      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 4,
      title: "Smart Fitness Watch",
      discount: "Up to 35% off",
      price: "$3,500 INR",
      oldPrice: "$4500",
      description: "Track your health and fitness with advanced sensors....",
      sellerName: "Alagar",

      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 5,
      title: "4K HD Drone",
      discount: "Up to 40% off",
      price: "$7,500 INR",
      oldPrice: "$10000",
      description: "Capture stunning aerial footage with this 4K drone....",
      sellerName: "Sanjay",
      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 6,
      title: "Gaming Mouse",
      discount: "Up to 15% off",
      price: "$600 INR",
      oldPrice: "$750",
      description: "Ergonomic design with customizable buttons for gamers....",
      sellerName: "JP",

      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 7,
      title: "Wireless Keyboard",
      discount: "Up to 10% off",
      price: "$900 INR",
      oldPrice: "$1000",
      description: "Sleek wireless keyboard with long battery life....",
      sellerName: "JP",

      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    },
    {
      id: 8,
      title: "HD Webcam",
      discount: "Up to 18% off",
      price: "$1,500 INR",
      oldPrice: "$1800",
      description: "High-definition webcam for video conferencing....",
      sellerName: "JP",

      imgSrc: require("../assetss/products/helmet.png"),
      country: "India",
      countryFlag: require("../assetss/India.png"),
    }
];

function Prod({ product }) {
  return (
    <div className="flex flex-col h-full max-w-[1400px]">
      <div className="bg-white shadow-lg rounded-lg p-4 w-[250px] mx-4 flex flex-col h-[380px] relative top-40 mb-72">
        <span className="bg-oceanBlue text-white text-[10px] py-1 px-2 rounded-md absolute top-0 right-0 shadow-lg">
          {product.discount}
        </span>
        <div className="flex justify-center mb-2">
          <img className="rounded-lg w-40 h-32" src={product.imgSrc} alt="Product" />
        </div>
        <h5 className="text-lg font-semibold">{product.title}</h5>
        <p className="text-sm text-gray-700 mb-2 flex-grow overflow-hidden">
          {product.description}
        </p>
        <div className="flex justify-start items-center">
          <p className="text-sm px-1">{product.sellerName}</p>
          <img src={product.countryFlag} alt="Flag" className="w-7 h-4 px-1" />
          <p className="text-sm">{product.country}</p>
          <button className="w-20 text-[12px] flex items-center justify-center bg-blue-100 text-oceanBlue font-extrabold px-4 rounded-md shadow-md ms-2">
            Verified
            <span className="ml-1">
              <PiShieldCheck />
            </span>
          </button>
        </div>
        <h5 className="text-lg font-semibold mt-2">
          {product.price}
          <span className="line-through text-[13px] text-gray-500 ml-2">
            {product.oldPrice}
          </span>
        </h5>
        <div className="flex justify-around mt-4 gap-2">
          <button className="bg-blood text-white font-semibold py-2 px-2 rounded-md">
            Get Quotes
          </button>
          <button className="border border-oceanBlue text-oceanBlue hover:bg-oceanBlue hover:text-white font-semibold py-2 px-4 rounded-md">
            Contact
          </button>
        </div>
      </div>
    </div>
  );
}

export const CardCompon = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(1); 

  useEffect(() => {
    const updateCardsPerPage = () => {
      if (window.innerWidth >= 1024) {
        setCardsPerPage(4); // Display 4 cards on large screens
      } else if (window.innerWidth >= 768) {
        setCardsPerPage(2); // Display 2 cards on medium screens
      } else {
        setCardsPerPage(1); // Display 1 card on small screens (mobile)
      }
    };

    updateCardsPerPage(); // Set on initial load
    window.addEventListener('resize', updateCardsPerPage); // Update on resize

    return () => {
      window.removeEventListener('resize', updateCardsPerPage); // Cleanup listener on unmount
    };
  }, []);

  const nextSlide = () => {
    if (currentIndex < products.length - cardsPerPage) {
      setCurrentIndex(currentIndex + cardsPerPage);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - cardsPerPage);
    }
  };

  return (
    <div 
      className="w-full bg-no-repeat bg-cover" // Full-width background
      style={{
        backgroundImage: window.innerWidth >= 768 ? `url(${require('../assetss/CardBAckg.jpg')})` : 'none'
      }}
    >
      <div className="max-w-[1400px] mx-auto relative flex flex-col items-center"> {/* Centered container */}
        <h1 className="absolute top-24 text-center md:text-white font-semibold text-3xl mb-18">Products with Attractive Price</h1>
        
        <button className="absolute left-4 top-1/2 transform -translate-y-1/2   text-5xl text-white hover:text-gray-400" onClick={prevSlide}>
          ❮
        </button>
        
        <div className="flex gap-4 justify-center">
          {products.slice(currentIndex, currentIndex + cardsPerPage).map((product) => (
            <Prod key={product.id} product={product} />
          ))}
        </div>
        
        <button className="absolute right-4 top-1/2 transform -translate-y-1/2 text-5xl text-white hover:text-gray-400" onClick={nextSlide}>
          ❯
        </button>
      </div>
    </div>
  );
};
