import React from 'react';
import DocumentationBanner from './SubComponents/DocumentationBanner/DocumentationBanner';
import Header from '../SubComponents/Header/Header';
import DocumentationMatters from './SubComponents/DocumentationMatters/DocumentationMatters';
import CertificationContent from './SubComponents/CertificationContent/CertificationContent';
import CertificateExcellence from './SubComponents/CertificateExcellence/CertificateExcellence';

const DocumentationCertify = () => {
  return (
    <div>
      
      <DocumentationBanner/>
      <DocumentationMatters/>
      <CertificationContent/>
      <CertificateExcellence/>
    </div>
  )
}

export default DocumentationCertify
