import React from 'react'
import Header from '../SubComponents/Header/Header'
import QualityVerifyBanner from './SubComponents/QualityVerifyBanner/QualityVerifyBanner';
import OurVerifyProcess from './SubComponents/OurVerifyProcess/OurVerifyProcess';
import QualityVerificationProcess from './SubComponents/VerificationProcess/QualityVerificationProcess';

const QualityVerification = () => {
  return (
    <div>
      
      <QualityVerifyBanner/>
      <OurVerifyProcess/>
      <QualityVerificationProcess/>
    </div>
  )
}

export default QualityVerification
