import React from 'react'
import DirectoryHome from './DirectoryHome'
import CarouselFadeExample from './CarouselFadeExample'
import ZmmDirService from './ZmmDirService'
import ApexChart from './ApexChart'
import Keyfeatures from './Keyfeatures'
import OurUniqueness from './OurUniqueness'
import PowerofZmm from './PowerofZmm'
import { Partners } from './Partners'
import SuccessStory from './SuccessStory'
import OurVision from './OurVision'

const DirectoryService = () => {
  return (
    <>
    <div className=''>
    <DirectoryHome/>
    <CarouselFadeExample/>
    <ZmmDirService/>
    <ApexChart/>
    <Keyfeatures/>
    <OurUniqueness/>
    <PowerofZmm/>
    <OurVision/>
    <Partners/>
    <SuccessStory/>
    </div>
    
    </>
  )
}

export default DirectoryService