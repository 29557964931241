import React from 'react';
import Header from '../SubComponents/Header/Header';
import PaymentFollowBanner from './SubComponents/PaymentFollowBanner/PaymentFollowBanner';
import PaymentFollowContent from './SubComponents/PaymentFollowContent/PaymentFollowContent';
import DocumentationReport from './SubComponents/DocumenatationReport/DocumentationReport';


const PaymentFollowUp = () => {
  return (
    <div>
      
      <PaymentFollowBanner/>
      <PaymentFollowContent/>
      <DocumentationReport/>
    </div>
  )
}

export default PaymentFollowUp
