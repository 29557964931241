import React from "react";
import think from "../assetss/think.png";
import NoPrice from "../assetss/WhyCHoose/NoPrice.png";

const WhyChooseUs = () => {
  return (
    <div className="max-w-[1400px] mx-auto h-full mt-5  bg-white">
      <div className="flex items-center justify-center text-blood">
        <h1 className="w-[400px] text-center lg:w-auto text-3xl font-semibold">
          Why Choose ZeroMiddleMan.com
        </h1>
      </div>

      <div className="flex flex-col lg:flex-row items-center bg-white justify-between mt-3 px-2 py-3 lg:max-w-[1400px] lg:mx-auto">
        {/* Content Section */}
        <div className="py-4 lg:skew-y-2 lg:ml-1">
          <div className="lg:border lg:shadow-2xl lg:bg-white p-5 h-auto lg:h-64 w-full ">
            <div>
              <h1 className=" text-center font-bold text-black text-xl">
                “Introducing Zeromiddleman.com:{" "}
                <span className="text-blood">
                  Connecting B2B Buyers Directly with the Source“
                </span>
              </h1>
            </div>
            <div className="mt-4">
              <p className="text-justify text-sm leading-8">
                Tired of inflated prices and low margins due to unnecessary
                middlemen? <span className="text-blood">Zeromiddleman.com</span> is here to change that. Our
                platform completely removes the need for brokers, agents,
                distributors, exporters, and other intermediaries who often
                drive up costs in the supply chain. By doing so, we help sellers
                from the primary sector (agriculture, forestry, fishing, and
                producers) and the secondary sector (manufacturing and industry)
                reach buyers directly, worldwide.
              </p>
              <p className="text-justify text-sm mt-3">
                Our mission is simple: <span className="text-blood">connect B2B buyers with the original
                source</span>—no middlemen, no inflated costs.
              </p>
            </div>
          </div>
        </div>

        {/* Image Section - Visible only on medium screens and larger */}
        <div className="hidden lg:block w-full">
          <img src={think} alt="think" />
        </div>
      </div>


      <div className="flex flex-col lg:flex-row items-center justify-center mt-1 px-2 lg:max-w-[1400px] lg:mx-auto">
       
        {/* Image Section - Visible only on medium screens and larger */}
 <div className="hidden lg:block w-3/4">
          <img src={NoPrice} alt="think" />
        </div>
        
        {/* Content Section */}
        <div className="py-4 lg:-skew-y-2 lg:ml-1">
          <div className="lg:border lg:shadow-2xl lg:bg-white p-5 h-auto lg:h-72 w-full">
            <div>
              <h1 className="text-start font-bold text-black text-xl">
                NO PRICE INTERFERENCE, NO HIDDEN AGENDAS
              </h1>
            </div>
            <div className="mt-4">
              <p className="text-justify text-sm pb-5">
              But that's not all! We also offer a professional <span className="text-blood">Liaison Service.</span>  Our experienced local coordinators ensure that every transaction runs smoothly and with full transparency. These trusted professionals help verify the authenticity of both the buyers and sellers, building trust and confidence throughout the process                    
              </p>
              <p className="text-justify text-sm pb-5">We don't influence the terms of your deals. Instead, we facilitate connections and offer additional services at a nominal charge, leaving you to focus on what matters most—your business.</p>
              
              <p className="text-justify text-sm pb-5"><span className="text-blood">Why wait? </span>Sign up today and start buying and selling <span className="text-blood">globally,effortlessly & directly</span></p>
              <p className="text-justify text-sm leading-8r">Make your move now and discover a world of opportunities. Let’s get started— <span className="text-blood">right NOW!</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
