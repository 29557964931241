import React from 'react';
import cardImg1 from '../../../../assetss/pricing card images/card-img-1.png';
import { FaRegHandPointRight } from "react-icons/fa";
import './Pricing.css'

const Pricing = () => {
    return (
        <div className="flex justify-center items-center lg:h-[500px] max-w-[1400px] mx-auto py-5 h-[1580px]">
            <div className="text-center">
                <h1 className="text-3xl font-bold mb-6">Pricing</h1>
                <div className="flex flex-wrap lg:flex-nowrap justify-center lg:h-[320px] ">
                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2 mx-3 h-[350px]">
                        <div className="relative lg:p-5 p-3 w-[300px] bg-white rounded-lg  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={cardImg1} alt="" className="w-[250px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[36px] left-[40px] shadow-md  z-10 bg-white bg-opacity-75 p-4 rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold">Company Registration</h6>
                                <a href="" className="block mt-2 bg-red-600 text-white text-center rounded-lg p-1  ">Starts From ₹ 2,000 INR</a>
                                <ul className="list-none pl-0 mt-2 text-sm">
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Private Limited Company
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        One Person Company
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Liability Partnership
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Sole Proprietorship
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Repeat the above block for the other cards, just changing the text and images */}
                    
                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2 mx-3 h-[350px]">
                        <div className="relative p-5 bg-white rounded-lg  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={cardImg1} alt="" className="w-[250px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[36px] left-[40px] shadow-md z-10 bg-white bg-opacity-75 p-4 rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold">Goods & Services Tax</h6>
                                <a href="" className="block mt-2 bg-red-600 text-white text-center rounded-lg p-1 ">Starts From ₹ 2,000 INR</a>
                                <ul className="list-none pl-0 mt-2 text-sm">
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        GST Registration
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        GST Filing
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        GST Advisory
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        GST For Individuals
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2  mx-3 h-[350px]">
                        <div className="relative p-5 bg-white rounded-lg  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={cardImg1} alt="" className="w-[250px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[36px] left-[40px] shadow-md z-10 bg-white bg-opacity-75 p-4 rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold">CoIgnitive Safeguard</h6>
                                <a href="" className="block mt-2 bg-red-600 text-white text-center rounded-lg p-1  ">Starts From ₹ 2,000 INR</a>
                                <ul className="list-none pl-0 mt-2 text-sm">
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Trademark Registration
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Trademark Watch
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Trademark Renewal
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Copyright Registration
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2  mx-3 h-[350px]">
                        <div className="relative p-5 bg-white rounded-lg  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={cardImg1} alt="" className="w-[250px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[36px] left-[40px] shadow-md z-10 bg-white bg-opacity-75 p-4 rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold">Accounting & Compliance</h6>
                                <a href="" className="block mt-2 bg-red-600 text-white text-center rounded-lg p-1  ">Starts From ₹ 2,000 INR</a>
                                <ul className="list-none pl-0 mt-2 text-sm">
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Maintain Your Accounts
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Compliance Packages
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Pro Tax Registration
                                    </li>
                                    <li className="flex items-center">
                                        <FaRegHandPointRight className='text-red-600 mr-1 pricing-list-icon' />
                                        Income Tax Return Filing
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end mt-6 me-2">
                    <button className="bg-red-600 text-white py-2 px-4 rounded-lg font-semibold">View All Services</button>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
