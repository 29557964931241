import React from 'react';
import vector1 from '../../../../../assetss/LocalNeeds/vector1.png';
import icon1 from '../../../../../assetss/LocalNeeds/icons/Confusion.png';
import icon2 from '../../../../../assetss/LocalNeeds/icons/Try And Buy.png';
import icon3 from '../../../../../assetss/LocalNeeds/icons/Archive List Of Parts.png';


const WhyChooseLocal = () => {
    return (
        <div>
            <div className="why-choose-local flex justify-center">
                <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] w-[full]'>
                    <div className='container mx-auto'>
                        <h1 className='text-3xl text-center font-bold p-2'>Why Choose Permanent Local Liaison Services?</h1>
                        <h4 className='text-2xl text-center font-bold p-2'>Having a local presence can significantly enhance your business operations by:</h4>
                        <div className='flex flex-wrap lg:flex-nowrap justify-center py-[20px]'>
                            <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full flex flex-col gap-[30px]'>
                                <h1 className='text-2xl font-semibold px-3 '>Effective after-sales support helps you:</h1>
                                <div className='flex px-[10px] justify-center'>
                                    <div className='flex items-center p-2 w-[70px]'>
                                        <img src={icon1} alt="" className='lg:w-[40px] lg:h-[40px]' />
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <h1 className='text-xl font-semibold'>Resolve Issues Promptly:</h1>
                                        <p>Quick solutions to any problems that arise after delivery.</p>
                                    </div>
                                </div>
                                <div className='flex px-[10px] justify-center'>
                                    <div className='flex items-center p-2 w-[70px]'>
                                        <img src={icon2} alt="" className='lg:w-[40px] lg:h-[40px]' />
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <h1 className='text-xl font-semibold'>Enhance Customer Satisfaction:</h1>
                                        <p>Build trust and loyalty with your clients through responsive service</p>
                                    </div>
                                </div>
                                <div className='flex px-[10px] justify-center'>
                                    <div className='flex items-center p-2 w-[70px]'>
                                        <img src={icon3} alt="" className='lg:w-[40px] lg:h-[40px]' />
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <h1 className='text-xl font-semibold'>Enhance Customer Satisfaction:</h1>
                                        <p>Ensure your team understands how to use and maximize the benefits of the products.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full'>
                                <img src={vector1} alt="" className='w-[full] h-[auto]' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseLocal
