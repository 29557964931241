import React from 'react';
import './PaymentFollowContent.css';
import vector1 from '../../../../../assetss/paymentFollowUp/vector1.png'
import vector2 from '../../../../../assetss/paymentFollowUp/vector2.png'

const PaymentFollowContent = () => {
    return (
        <div>
            <div className="payment-follow-content flex justify-center">
                <div className='xl:max-w-[1400px]  xl:w-[full] lg:max-w-[1279px] lg:w-[full] lg:h-[900px] py-[30px] lg:flex lg:flex-col lg:gap-[40px]'>
                    <div>
                        <h1 className='text-3xl font-bold text-center'>Payment Follow-Up</h1>
                        <div className='flex flex-wrap lg:flex-nowrap justify-center'>
                            <div className='lg:w-2/3 md:w-1/2 sm-[full] w-[full]'>
                                <div className='relative'>
                                    <div className='lg:w-[400px] lg:text-left text-center py-[10px]'>
                                        <h1 className='text-xl font-semibold py-3'>Time Reminders</h1>
                                        <p>We send regular updates on payment schedules and outstanding invoices to keep you informed and on track.</p>
                                    </div>
                                    <div className='lg:w-[400px] lg:absolute lg:left-[190px] lg:top-[150px] lg:text-left text-center py-[10px]'>
                                        <h1 className='text-xl font-semibold py-3'>Flexible Solutions</h1>
                                        <p>Our team is available to discuss payment options and address any concerns you may have, ensuring that the process meets your needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='lg:w-1/3 md:w-1/2 sm-full w-full'>
                                <img src={vector1} alt="vector1" className='w-[full] h-[auto]' />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className='text-3xl font-bold text-center'>Quality Assurance</h1>
                        <div className='flex flex-wrap lg:flex-nowrap justify-center pt-[20px]'>
                            <div className='lg:w-1/3 md:w-1/2 sm-full w-full'>
                                <img src={vector2} alt="vector1" className='w-[full] h-[auto]' />
                            </div>
                            <div className='lg:w-2/3 md:w-1/2 sm-full w-full'>
                            <div className='relative'>
                                <div className='lg:w-[400px] lg:text-left text-center py-[10px]'>
                                    <h1 className='text-xl font-semibold py-3'>Inspection Protocols</h1>
                                    <p>Before shipment, we conduct through quality checks to ensure that all products meet the required standards.</p>
                                </div>
                                <div className='lg:w-[400px] lg:absolute lg:left-[190px] lg:top-[150px] lg:text-left text-center py-[10px]'>
                                    <h1 className='text-xl font-semibold py-3'>Issue Resolution</h1>
                                    <p>In the rare event of quality discrepancies or documentation issues, our team promptly addresses these concerns, providing solutions to rectify the situation.</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default PaymentFollowContent
