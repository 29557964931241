// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Outlet } from 'react-router-dom';
import { Footer } from './components/Footer';


function App() {
  return (
   <>
   <Outlet/>
   <Footer/>
   </>
  );
}

export default App;
