import React, { useState, useEffect, useCallback } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
export const CarouselFadeExample = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); 

  const carouselItems = [
    {
      imgSrc: require("../assetss/Buyer.jpg"),
      title: "Connect with Trusted Business",
      subtitle: "“  Connect with trusted local and global businesses effortlessly at Zero Middle Man!  ”",
      description:
       "“  Connect with trusted local and global businesses effortlessly at Zero Middle Man!  ”",
      description2:
        "Tired of inflated prices and low margin due to unnecessary middlemen?",
      extraInfo: "Your Gateway to Trusted Business",
      extraInfo2: "Your Gateway to Success",
      buttonText: "Get Started",
    },
    {
      imgSrc: require("../assetss/Seller.jpg"),
      title: "Explore Our Services",
      subtitle: "List your business with us for FREE today!",
      description:
      "“  Explore our services and discover how we can help you connect, communicate, and succeed  ”",
         extraInfo:
        "Don't miss out on this opportunity to connect directly with buyers worldwide!",
      buttonText: "Get Started",
    },
    {
      imgSrc: require("../assetss/Experience.jpg"),
      title:
        "Connect Directly with verified Global Source",
      subtitle: "Cut out the middlemen and expand your reach",
      description: "“  Cut out the middlemen and expand your reach Easily download all contacts in an Excel file and reach out at your convenient  ” ",
      extraInfo: "file and reach out at your convenience.",
      buttonText: "Get Started",
    },
  ];

  // Memoize nextSlide function
  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
    );
  }, [carouselItems.length]);

  // Handle previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1
    );
  };

  // Auto-slide functionality with a 3-second interval
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        nextSlide();
      }, 1500); // Change slide every 1.5 seconds

      return () => clearInterval(interval);
    }
  }, [isPaused, nextSlide]);

  return (
    <>
      <div
        className="relative overflow-hidden"
        onMouseEnter={() => setIsPaused(true)} // Pause on hover
        onMouseLeave={() => setIsPaused(false)} // Resume on mouse leave
      >
        {/* Carousel Wrapper */}
        <div
          className="relative w-full h-[300px] md:h-[450px] flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {carouselItems.map((item, index) => (
            <div
              key={index}
              className="relative w-full flex-shrink-0 bg-cover "
              style={{ backgroundImage: `url(${item.imgSrc})` }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="text-center text-white px-4">
                  <h3 className="text-[24px] text-blood md:text-[30px] font-bold ">
                    {item.title}
                  </h3>

                  <p className="flex justify-center text-center text-[14px] md:text-[20px] mt-2 w-1/2 mx-auto">
                    {item.description}
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-5 transform -translate-y-1/2 p-1 text-3xl bg-white rounded-full shadow-lg text-black hover:bg-gray-100"
        >
                   <FaArrowCircleLeft />

        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-5 transform -translate-y-1/2 p-1 text-3xl bg-white rounded-full shadow-lg text-black hover:bg-gray-100"
        >
                   <FaArrowCircleRight />

        </button>
      </div>
    </>
  );
};

export default CarouselFadeExample;
