import React from 'react'
import { Header } from './Header'
import { CarouselLP } from './CarouselLP'
import { Buyer } from './Buyer'
import { Cards } from './Cards'
import { Seller } from './Seller'
import { ExploreProducts } from './ExploreProducts'
import { KeyFeatures } from './KeyFeatures'
import { Partners } from './Partners'
import { Welcome } from './Welcome'
import SuccessStory from './SuccessStory'
// import { CardCompon } from '../components/CardCompon'

const ListingService = () => {
  return (
    <div className=''>
        <Header/>
        <CarouselLP/>
        <Welcome/>
        <Buyer/>
        <Cards/>
        {/* <SuccessStory/> */}
        <Seller/>
        <ExploreProducts/>
        <KeyFeatures/>
        <Partners/>
        {/* <CardCompon/> */}
    </div>
  )
}
export default ListingService
