import React, { useState, useEffect, useCallback } from "react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";

export const CarouselFadeExample = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false); // Track whether the carousel is paused

  const carouselItems = [
    {
      imgSrc: require("../assetss/Buyer.jpg"),
      title: "Welcome to ZeroMiddleman.com",
      subtitle: "Connecting B2B buyers directly to the SOURCE",
      description:
        "Start Selling & Sourcing at right cost from the Original Source!",
      description2:
        "Tired of inflated prices and low margin due to unnecessary middlemen?",
      extraInfo: "Your Gateway to Trusted Business",
      extraInfo2: "Your Gateway to Success",
      buttonText: "Get Started",
    },
    {
      imgSrc: require("../assetss/Seller.jpg"),
      title: "Are You the Original Source for a Product or Service?",
      subtitle: "List your business with us for FREE today!",
      description:
        "Simply submit your application, and once we verify that you're the genuine source, you'll start receiving global inquiries.",
      extraInfo:
        "Don't miss out on this opportunity to connect directly with buyers worldwide!",
      buttonText: "Get Started",
    },
    {
      imgSrc: require("../assetss/Experience.jpg"),
      title:
        "Connect directly with verified global sources or access thousands of potential buyers worldwide",
      subtitle: "Cut out the middlemen and expand your reach",
      description: "Easily download all contacts in an Excel",
      extraInfo: "file and reach out at your convenience.",
      buttonText: "Get Started",
    },
  ];

  // Memoize nextSlide function
  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
    );
  }, [carouselItems.length]);

  // Handle previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1
    );
  };

  // Auto-slide functionality with a 3-second interval
  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        nextSlide();
      }, 1500); // Change slide every 1.5 seconds

      return () => clearInterval(interval); // Clear interval on component unmount or when paused
    }
  }, [isPaused, nextSlide]);

  return (
    <>
      <div
        className="relative overflow-hidden"
        onMouseEnter={() => setIsPaused(true)} // Pause on hover
        onMouseLeave={() => setIsPaused(false)} // Resume on mouse leave
      >
        {/* Carousel Wrapper */}
        <div
          className="relative w-full h-[300px] md:h-[450px] flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {carouselItems.map((item, index) => (
            <div
              key={index}
              className="relative w-full flex-shrink-0 bg-cover bg-center"
              style={{ backgroundImage: `url(${item.imgSrc})` }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="text-center text-white px-4">
                  <h3 className="text-[24px] md:text-[44px] font-bold">
                    {item.title}
                  </h3>
                  <h5 className="text-[18px] md:text-[24px] font-bold">
                    {item.subtitle}
                  </h5>
                  <p className="text-[14px] md:text-[20px] mt-2">
                    {item.description}
                  </p>
                  <p className="text-[14px] md:text-[20px] mt-2">
                    {item.description2}
                  </p>
                  {item.extraInfo && (
                    <p className="mt-2 text-[14px] md:text-[16px]">
                      {item.extraInfo}
                    </p>
                  )}
                  {item.extraInfo2 && (
                    <p className="mt-2 text-[14px] md:text-[16px]">
                      {item.extraInfo2}
                    </p>
                  )}
                  <button className="mt-4 px-4 py-2 bg-transparent border-2 border-blood text-blood font-bold rounded hover:bg-blood hover:text-white transition text-[14px] md:text-[16px]">
                    {item.buttonText}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-5 transform -translate-y-1/2 p-1 text-3xl bg-white rounded-full shadow-lg text-black hover:bg-gray-100"
        >
         <FaArrowCircleLeft />
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-5 transform -translate-y-1/2 p-1 text-3xl bg-white rounded-full shadow-lg text-black hover:bg-gray-100"
        >
        <FaArrowCircleRight />
        </button>
      </div>
    </>
  );
};

export default CarouselFadeExample;
