import React from 'react'
import Header from '../SubComponents/Header/Header';
import AfterSalesBanner from './SubComponents/AfterSalesBanner/AfterSalesBanner';
import TrustPartners from './SubComponents/TrustPartners/TrustPartners';
import WhyAfterSales from './SubComponents/WhyAfterSales/WhyAfterSales';
import SalesServices from './SubComponents/SalesServices/SalesServices';
import BenifitsAfterSales from './SubComponents/BenifitsAfterSales/BenifitsAfterSales';

const AfterSalesSupport = () => {
  return (
    <div>
   
      <AfterSalesBanner/>
      <TrustPartners/>
      <WhyAfterSales/>
      <SalesServices/>
      <BenifitsAfterSales/>
    </div>
  )
}

export default AfterSalesSupport
