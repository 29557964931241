import React from 'react';
import './RealTimeUpdateBanner.css'

const RealTimeUpdateBanner = () => {
    const bannerTitle = "Quality Verification";
    const splitTitle = (title) => {
        const midIndex = Math.ceil(title.length / 2);
        const title1 = title.slice(0, midIndex);
        const title2 = title.slice(midIndex);
        return { title1, title2 }
    }
    const { title1, title2 } = splitTitle(bannerTitle);
    const RealTimeUpdate = [
        {
            title1: title1,
            title2: title2,
            description: "At ZMM, we prioritize quality and compliance throughout our import export process. Our rigorous approach to quality verification, through various testi ng ensures that our clients receive only the best products while adhering to international standards.",
        }
    ]
  return (
    <div>
      <div className="real-time-banner flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] h-[470px] flex items-center'>
            {RealTimeUpdate.map((banner,index)=>(
               <div key={index} className='flex flex-col items-center justify-center text-white py-3'>
               <h1 className='lg:text-3xl text-xl text-center font-semibold'><span className='text-red-600 '>{banner.title1}</span>{banner.title2}</h1>
               <span className='text-center leading-loose lg:px-[60px] py-3'>{banner.description}</span>
           </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default RealTimeUpdateBanner
