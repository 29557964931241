import React from 'react';
import rightArrow from '../../../../../assetss/qualityVerification/arrowRight.png';
import leftArrow from '../../../../../assetss/qualityVerification/arrowLeft.png';

const OurVerifyProcess = () => {
    return (
        <div>
            <div className='flex justify-center'>
            <div className='verification-process xl:max-w-[1400px] xl:w-[1400px] lg:w-[1279px] lg:max-w-[1279px] lg:h-[1400px] py-3'>
                <h1 className='text-3xl text-center font-bold'>Our Verification Process</h1>
                <div className='verify-process-cards lg:relative p-5'>
                    <div className='flex gap-5 lg:absolute xl:left-[100px] lg:left-[50px] py-3'>
                        <div className="verify-product-card lg:w-[500px] lg:h-[160px] border-2  border-[#69BCDF] rounded-lg p-3 text-center">
                            <h1 className='text-xl font-semibold'>Comprehensive Inspections </h1>
                            <p>Our quality assurance team conducts detailed inspections at every stage of the supply chain. This includes pre shipment
                                inspections to ensure the product meet the specification criteria.</p>
                        </div>
                        <div className='hidden lg:block p-3 pt-5'>
                            <img src={leftArrow} alt="" />
                        </div>
                    </div>
                    <div className='flex gap-5 lg:absolute xl:top-[220px] xl:left-[450px] lg:top-[220px] lg:left-[350px] py-3'>
                        <div className="verify-product-card lg:w-[500px] lg:h-[160px] border-2  border-[#69BCDF] rounded-lg p-3 text-center">
                            <h1 className='text-xl font-semibold'>Product testing </h1>
                            <p>We perform various tests to verify the products comply with industry standards and regulations, ensuring safety and reliability.</p>
                        </div>
                        <div className='hidden lg:block p-3 pt-5'>
                            <img src={leftArrow} alt="" />
                        </div>
                    </div>
                    <div className='lg:absolute xl:top-[430px] xl:left-[800px] lg:top-[430px] lg:left-[700px] py-3'>
                        <div className="verify-product-card lg:w-[500px] lg:h-[160px] border-2  border-[#69BCDF] rounded-lg p-3 text-center">
                            <h1 className='text-xl font-semibold'>Random Sampling</h1>
                            <p>Our team performs random sampling of products during production and pre-shipment to ensure a representative quality
                                check, reducing the chances of defects in your final shipment.</p>
                        </div>
                        <div className='hidden lg:block p-3'>
                            <img src={rightArrow} alt="" />
                        </div>
                        </div>
                    <div className='lg:absolute  xl:top-[690px] xl:left-[450px] lg:top-[690px] lg:left-[350px] py-3'>
                        <div className="verify-product-card lg:w-[500px] lg:h-[160px] border-2  border-[#69BCDF] rounded-lg p-3 text-center">
                            <h1 className='text-xl font-semibold'>Supplier Audits </h1>
                            <p>We evaluate your suppliers to ensure they adhere to industry standards and best practices. This includes assessing their
                                manufacturing processes, quality control measures, and compliance with regulatory requirements.</p>
                        </div>
                        <div className='hidden lg:block p-3'>
                            <img src={rightArrow} alt="" />
                        </div>
                    </div>
                    <div className='flex gap-5 lg:absolute xl:top-[950px] xl:left-[100px] lg:top-[950px] lg:left-[50px] py-3'>
                        <div className="verify-product-card lg:w-[500px] lg:h-[160px] border-2  border-[#69BCDF] rounded-lg p-3 text-center">
                            <h1 className='text-xl font-semibold'>Pre-Shipment Inspection </h1>
                            <p>We conduct thorough inspections of your goods before they leave the manufacturer’s facility. This includes checking for defects,
                                compliance with specifications, and ensuring that all required documentation is in order.</p>
                        </div>
                        <div className='hidden lg:block p-3 pt-8'>
                            <img src={leftArrow} alt="" />
                        </div>
                    </div>
                    <div className='lg:absolute xl:top-[1150px] xl:left-[450px] lg:top-[1150px] lg:left-[350px] py-3'>
                        <div className="verify-product-card lg:w-[500px] lg:h-[160px] border-2  border-[#69BCDF] rounded-lg p-3 text-center">
                            <h1 className='text-xl font-semibold'>Ongoing Monitoring </h1>
                            <p>Quality doesn’t stop at shipment. We offer ongoing monitoring and support to ensure that your supply chain maintains high
                                standards overtime</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
    )
}

export default OurVerifyProcess
