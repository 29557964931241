import React from 'react';
import './LocalNeedsBanner.css'

const LocalNeedsBanner = () => {
    const bannerTitle = "Bridging Global Trade with Local Expertise";
    const splitTitle = (title) => {
        const midIndex = Math.ceil(title.length / 2);
        const title1 = title.slice(0, midIndex);
        const title2 = title.slice(midIndex);
        return { title1, title2 }
    }
    const { title1, title2 } = splitTitle(bannerTitle);
    const LocalNeedsBanner = [
        {
            title1: title1,
            title2: title2,
            description: "As your local representative, we act as your trusted partner for all import and export needs. Our knowledgeable staff is equipped to handle inquires, facilitate communication, and provide timely support, ensuring a seamless experience throughout your engagement with us. We understand the importance of reliability and efficiency in international trade and we are committed to being there for you every step of the way",
        }
    ]
  return (
    <div>
      <div className="local-needs-banner flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px h-[469px] flex items-center'>
        {LocalNeedsBanner.map((banner, index) => (
                        <div key={index} className='flex flex-col items-center justify-center text-white py-3'>
                            <h1 className='lg:text-3xl text-xl text-center font-semibold'><span className='text-red-600 '>{banner.title1}</span>{banner.title2}</h1>
                            <span className='text-center leading-loose lg:px-[60px] py-3'>{banner.description}</span>
                        </div>
                    ))}
        </div>
      </div>
    </div>
  )
}

export default LocalNeedsBanner
