import React from 'react';
import { IoIosPeople } from "react-icons/io";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { PiHeadsetBold } from "react-icons/pi";
import Arrow from '../../../../assetss/BannerCarousel/htw-arrow.png';

const HowItWorks = () => {
  return (
    <div className="xl:max-w-[1400px]  mx-auto flex flex-col py-5 font-inter lg:h-[800px] h-[820px] max-w-[full]  ">
      <h1 className="text-center font-bold text-4xl">How It Works</h1>
      <div className="relative py-5 px-3">
        <div className=" space-y-6 lg:flex-col">
          <div className='lg:flex lg:items-center flex justify-center items-center lg:justify-start'>
          <div className="bg-white text-[#33333] w-[300px] shadow-lg h-[200px] border border-[#0C92CC]   rounded-lg  p-3">
                <IoIosPeople className="text-[60px] text-[#0C92CC] pb-2" />
                <h5 className="font-bold">Discuss Your Requirements</h5>
                <span className="text-[14px]">Share your specific needs with our team, and we’ll develop a customized solution.</span>
              </div>
              <div className="lg:absolute lg:left-[350px] lg:top-[80px] hidden lg:block ">
                <img src={Arrow} className="w-[300px] h-[130px]" alt="Arrow 1" />
              </div>
          </div>

          <div className='flex items-center justify-center'>
            <div className="bg-white text-[#33333] w-[300px] shadow-lg h-[200px] border border-[#0C92CC]  rounded-lg py-2 p-3">
              <MdOutlineMiscellaneousServices className="text-[60px] text-[#0C92CC] pb-2" />
              <h5 className="font-bold">Tailored Service Options</h5>
              <span className="text-[14px]">We offer flexible service packages that can be adjusted based on your industry, budget, and operational goals.</span>
            </div>
            <div className="absolute right-[200px] top-[300px] hidden lg:block">
              <img src={Arrow} className="w-[300px] h-[130px]" alt="Arrow 2" />
            </div>
          </div>

          <div className='lg:flex lg:items-center lg:justify-end flex items-center justify-center'>
            <div className="bg-white text-[#33333] w-[300px] shadow-lg h-[200px] border border-[#0C92CC]  rounded-lg py-2 p-3">
              <PiHeadsetBold className="text-[60px] text-[#0C92CC] pb-2" />
              <h5 className="font-bold">Ongoing Support</h5>
              <span className="text-[14px]">Once your customized solution is implemented, we provide ongoing support to ensure it continues to meet your evolving needs.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
