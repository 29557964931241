import React, { useState } from 'react'
import profile from "../assetss/john.png";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";


export const Testimonial = () => {
    const slides = [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        "Another slide content here.",
        "More information in this slide.",
        "Yet another slide with interesting content.",
      ];
      const [currentIndex, setCurrentIndex] = useState(0);

      const handleLeftClick = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === 0 ? slides.length - 1 : prevIndex - 1
        );
      };
 
      const handleRightClick = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === slides.length - 1 ? 0 : prevIndex + 1
        );
      };
  return (
    <div className="w-full h-[521px] flex justify-center items-center TestinomialImg">
      <div className="flex flex-col items-center justify-center max-w-[1400px] h-[330px]  text-white font-bold rounded-[20px] bg-black bg-opacity-50 backdrop-blur-lg relative">
        <div className="">
          <button
            onClick={handleLeftClick}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-3xl" 
          >
            <FaArrowCircleLeft />
          </button>
          <p className="text-sm mx-10 md:text-lg italic text-center md:mx-32">
            <span>"</span>
            {slides[currentIndex]}
          </p>
          <button
            onClick={handleRightClick}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-3xl"
          >
<FaArrowCircleRight />

          </button>
        </div>
        <img src={profile} alt="profile" className="mt-4" />
        <p className="italic text-lg">JOHN SMITH</p>
      </div>
    </div>
  )
}
