import React from 'react'
import './WhyChooseNeeds.css';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import vector1 from '../../../../../assetss/whyChooseNeeds/vector1.png'
const WhyChooseNeeds = () => {
    return (
        <div>
            <div className="why-choose-needs flex justify-center">
                <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] lg:h-[540px] py-[30px] text-white'>
                    <div className="container mx-auto">
                        <h1 className='text-3xl font-bold text-center '>Why Choose Us</h1>
                        <div className='flex flex-wrap lg:flex-nowrap py-[20px]'>
                            <div className='lg:w-3/5 md:w-3/5 sm:w-full w-full flex items-center py-[20px]'>
                                <div className='flex flex-col gap-[20px] '>
                                <div className='flex gap-[10px] px-[10px]'>
                                        <IoMdCheckmarkCircleOutline className='text-3xl text-white' />
                                        <div>
                                            <h1 className='text-lg font-semibold'>Expertise</h1>
                                            <p>Our team has extensive industry knowledge to address any concerns or requirements.</p>
                                        </div>
                                    </div>

                                    <div className='flex gap-[10px] px-[10px]'>
                                        <IoMdCheckmarkCircleOutline className='text-3xl text-white' />
                                        <div>
                                            <h1 className='text-lg font-semibold'>Accessibility</h1>
                                            <p>With local representaƟ ves, assistance is always within reach.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[10px] px-[10px]'>
                                        <IoMdCheckmarkCircleOutline className='text-3xl text-white' />
                                        <div>
                                            <h1 className='text-lg font-semibold'>Tailored solutions</h1>
                                            <p>We offer customized support to meet the unique needs of your business.</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-[10px] px-[10px]'>
                                        <IoMdCheckmarkCircleOutline className='text-3xl text-white' />
                                        <div>
                                            <h1 className='text-lg font-semibold'>Ongoing support</h1>
                                            <p>From initial purchase to long term service, we are dedicated to your satisfaction.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='lg:w-2/5 md:w-2/5 sm:w-full w-full p-[20px]'>
                                <img src={vector1} alt="" className='w-[full] h-[auto]' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WhyChooseNeeds
