import React from "react";

const PowerofZmm = () => {
  return (
    <>
   <div className="flex justify-center bg-white max-w-[1400px] px-5 mx-auto">
   <div className="max-w-[1400px]">

<div className="bg-white shadow-lg shadow-gray-200 m-6 rounded-lg px-5 py-1 lg:mx-2 ">
    <h1 className="text-blood text-xl text-center font-semibold lg:text-3xl lg:pb-10">Experience the Power of ZeroMiddleMan</h1>
    <p className="text-justify lg:px-60 lg:text-center lg:hover:font-semibold">Ready to take your business to the next level? schedule personalized demo us and explore how our platform can streamline your buying and selling processes. Gain access to a global directory of buyer and seller contacts, making it easier than ever to connect and grow your network !    </p>
    <div className="flex justify-evenly p-5 lg:justify-center lg:gap-4">
        <button className="border border-skyblue rounded-lg px-5 py-2 text-sm font-bold hover:bg-oceanBlue hover:text-white ">Book Demo</button>
        <button className="border bg-blood  text-white rounded-lg px-5 py-2 text-sm font-bold">View pricing</button>
       
    </div>
  </div>
</div>
   </div>
     
    </>
  );
};

export default PowerofZmm;
