import React from 'react'
import Header from '../SubComponents/Header/Header';
import BannerCarousel from '../SubComponents/BannerCarousel/BannerCarousel';
import HowItWorks from '../SubComponents/HowItWorks/HowItWorks';
import MappingServices from '../SubComponents/MappingServices/MappingServices';
import Pricing from '../SubComponents/Pricing/Pricing';
import StatisticsCounter from '../SubComponents/StatisticsCounter/StatisticsCounter';
import RFQSection from '../SubComponents/RFQSection/RFQSection';
import Testimonials from '../SubComponents/Testimonials/Testimonials';
const LiaisionHome = () => {
  return (
    <div>
    
    <BannerCarousel/>
    <HowItWorks/>
    <MappingServices/>
    <Pricing/>
    <StatisticsCounter/>
    <RFQSection/>
    <Testimonials/>
    </div>
  )
}

export default LiaisionHome
