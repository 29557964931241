import React from "react";
// import "./Buyer.css"; 

export const KeyFeatures = () => {
  // Array to store the key features data
  const cards = [
    {
      title: "Accurate Information",
      description:
        "We ensure unbiased and accurate information of businesses across the world with regular updates to ensure consistency and accuracy of business details.",
    },
    {
      title: "Global Reach",
      description:
        "Our platform provides businesses with a global reach, connecting them to potential clients and partners worldwide.",
    },
    {
      title: "Global Reach",
      description:
        "Our platform provides businesses with a global reach, connecting them to potential clients and partners worldwide.",
    },
    {
      title: "Verified Businesses",
      description:
        "All businesses listed on our platform go through a verification process to ensure authenticity and trustworthiness.",
    },
    {
      title: "Easy Accessibility",
      description:
        "Our user-friendly platform allows businesses to easily update their information and stay connected with the global market.",
    },
    {
      title: "Real-Time Updates",
      description:
        "Get real-time updates about market trends, business opportunities, and potential partnerships to stay ahead of the competition.",
    },
  ];

  return (
    <div className="container max-w-[1400px] bg-white mx-auto  py-4 px-5">
      <div>
        <h1 className="text-blood text-center text-3xl font-semibold">Key Features</h1>
        <p className="py-7 text-center mx-64">We ensure unbiased and accurate informa on of business across the world with regular updates to ensure consistency and accuracy of business details.</p>
      </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      
      {cards.map((card, index) => (
        <div
          key={index}
          className="border border-oceanBlue p-6 rounded-md text-center  hover:text-white hover:bg-oceanBlue transition-colors duration-300"
        >
          <h2 className="font-semibold mb-2 hover:text-white transition-colors duration-100">
            {card.title}
          </h2>
          <p className="transition-colors duration-100">{card.description}</p>
        </div>
      ))}
    </div>
  </div>
  
  );
};
