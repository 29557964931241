import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = () => {
  const series4 = [
    {
      data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
    },
  ];
  const options4 = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "South Korea",
        "Canada",
        "United Kingdom",
        "Netherlands",
        "Italy",
        "France",
        "Japan",
        "United States",
        "China",
        "Germany",
      ],
    },
    responsive: [
      {
        breakpoint: 640, // Adjust for mobile view (640px and below)
        options: {
          chart: {
            height: 250, // Smaller height for mobile
            width: 280, // Smaller width for mobile
          },
        },
      },
    ],
  };

  const series3 = [44, 15, 41, 17, 15];
  const options3 = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50px',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              formatter: () => 100,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 640,
        options: {
          chart: {
            height: 250,
            width: 280,
          },
        },
      },
    ],
  };

  const series2 = [44, 55, 41, 17, 15];
  const options2 = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: '50px',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              formatter: () => 100,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 640,
        options: {
          chart: {
            height: 250,
            width: 280,
          },
        },
      },
    ],
  };

  const series = [
    {
      name: "sales",
      data: [
        { x: "India", y: 13400 },
        { x: "China", y: 4030 },
        { x: "Japan", y: 7448 },
        { x: "Pakistan", y: 9470 },
        { x: "Germany", y: 5000 },
        { x: "USA", y: 5080 },
        { x: "Portugal", y: 17000 },
      ],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      height: 400,
      
    },
    xaxis: {
      type: "category",
      labels: {
        style: {
          fontSize: "10px", // Smaller font size for x-axis labels
          fontWeight: 300, // Light font weight
        },
        formatter: function (val) {
          return val;
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "10px", // Smaller font size for y-axis labels
          fontWeight: 300, // Light font weight
        },
      },
    },
    title: {
      text: "Top 7 Countries Contacts",
      style: {
        fontSize: "14px", // Adjust title size
        fontWeight: 400, // Title font weight
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "8px", // Small font size for data labels
        fontWeight: 700, // Light font weight
      },
    },
    tooltip: {
      style: {
        fontSize: "14px", // Small font size for tooltip
        fontWeight: 300, // Light font weight
      },
      x: {
        formatter: function (val) {
          return val;
        },
      },
    },
    legend: {
      fontSize: "14px", // Smaller font for legend (if applicable)
      fontWeight: 300, // Light font weight
    },
    responsive: [
      {
        breakpoint: 640,
        options: {
          chart: {
            height: 250,
            width: 280,
          },
        },
      },
    ],
  };

  return (
    <div className="flex justify-center bg-white max-w-[1400px] px-3  mx-auto">
    <div className="py-5 max-w-[1400px]">
      <div>
        <h1 className="text-3xl font-semibold text-center text-blood">
          Quick Statistics for a Glance
        </h1>
        <p className="px-5 lg:px-64 text-justify md:text-center py-5">
          Discover, connect, and engage with local and International businesses,
          services, and professionals. Our directory is designed to help you
          find exactly what you need in your business and personal quickly and
          easily.
        </p>
      </div>

      <div className=" lg:rounded-3xl  mx-1 lg:mx-2 p-5 lg:shadow-lg">
        <div className="mb-10">
          <h1 className="text-3xl font-semibold text-center text-blood">
            Zmm Analytics
          </h1>
          <p className="px-0 md:px-5 lg:px-64 text-justify  md:text-center">
            Discover, connect, and engage with local and International
            businesses, services, and professionals.
          </p>
        </div>

        {/* Chart section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-center">
          {/* First Chart */}
          <div id="chart" className="mx-auto">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={280}
              width={350}
            />
          </div>

          {/* Second Chart */}
          <div id="chart2" className="mx-auto">
            <ReactApexChart
              options={options2}
              series={series2}
              type="donut"
              height={300}
              width={350}
            />
          </div>

          {/* Third Chart */}
          <div id="chart3" className="mx-auto">
            <ReactApexChart
              options={options3}
              series={series3}
              type="donut"
              height={300}
              width={350}
            />
          </div>

          {/* Fourth Chart */}
          <div id="chart4" className="mx-auto">
            <ReactApexChart
              options={options4}
              series={series4}
              type="bar"
              height={300}
              width={350}
            />
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ApexChart;
