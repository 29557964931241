import React from 'react'

export const Welcome = () => {
  return (
    
      <div className="max-w-[1400px] mx-auto px-5 pt-10 pb-1 bg-white ">
  <div className="text-center mb-10">
    <h3 className="text-blood text-3xl font-semibold mb-4">Welcome to Zero Middleman Listing Service</h3>
    <p className="text-lg text-gray-700">Our ultimate platform for direct connections!</p>
  </div>

  <div className=" mx-auto rounded-lg bg-gray-100 p-2 text-center shadow-lg ">
    <p className="text-base text-justify leading-relaxed text-gray-800">
      We bridge the gap between buyers and the real sources like farmers, producers, and manufacturers globally, eliminating the need for brokers and middlemen. Whether you're sourcing raw materials, finished products, or specialized services, we make it effortless to connect with trusted and original sources worldwide. Get exactly what you need, when you need it—efficiently and transparently from its real owner! At Zeromiddleman.com, we are dedicated to transforming the way businesses connect. Built to foster global trade and collaboration, our platform empowers buyers and manufacturers, producers, and farmers to engage directly, ensuring seamless transactions and trusted partnerships. Experience hassle-free sourcing with us!
    </p>
  </div>
</div>
    
  )
}
