import React, { useState, useEffect } from 'react';
import { GrServices } from "react-icons/gr";
import { FaCity, FaHandshake, FaPeopleGroup } from "react-icons/fa6";
import './StatisticsCounter.css'

const StatisticsCounter = () => {
    // Define an array of counters, each with a target value and duration
    const counters = [
        { targetValue: 110, duration: 2000, icon: <GrServices />, context: "Services Offered" },
        { targetValue: 140, duration: 2000, icon: <FaCity />, context: "Cities" },
        { targetValue: 11000, duration: 3000, icon: <FaHandshake />, context: "Clients Served" },
        { targetValue: 110, duration: 2000, icon: <FaPeopleGroup />, context: "Qualified Professionals" }
    ];

    // Function to manage each individual counter state
    const CounterItem = ({ targetValue, duration, icon, context }) => {
        const [currentValue, setCurrentValue] = useState(0);

        useEffect(() => {
            const increment = targetValue / (duration / 100);
            let counter = 0;

            const interval = setInterval(() => {
                counter += increment;
                if (counter >= targetValue) {
                    setCurrentValue(targetValue);
                    clearInterval(interval);
                } else {
                    setCurrentValue(Math.floor(counter));
                }
            }, 100);

            return () => clearInterval(interval);
        }, [targetValue, duration]);

        return (
            <div className="flex flex-col items-center text-center p-5 border border-gray-300 rounded-lg w-52 mx-2 shadow-lg hover:hover:scale-105">
                <div className="text-white text-6xl py-3 lg:text-3xl">{icon}</div>
                <h6 className="text-3xl text-white font-medium mb-2">{currentValue}</h6>
                <p className="text-white text-sm">{context}</p>
            </div>
        );
    };

    return (
        <div className='Statistics-counter'>
        <div className='lg:max-w-[1400px] mx-auto flex flex-col justify-center bg-cover bg-center bg-no-repeat lg:h-[350px] h-[1000px] w-full p-5 '>
            <div></div>
            <h1 className="text-white font-semibold text-center lg:text-4xl mb-4 text-4xl py-2">BizAdvisors In Number</h1>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-8 py-5">
                {counters.map((counter, index) => (
                    <CounterItem
                        key={index}
                        targetValue={counter.targetValue}
                        duration={counter.duration}
                        icon={counter.icon}
                        context={counter.context}
                    />
                ))}
            </div>
        </div>
        </div>
    );
};

export default StatisticsCounter;
