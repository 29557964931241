import React, { useState } from "react";
import logo from "../assetss/ZeroMiddleMan Logo 3.png";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoHome } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import { IoMenu } from "react-icons/io5";
import { ImCancelCircle } from "react-icons/im";
import { Link } from "react-router-dom";

export const Header = () => {
  const [placeholder, setPlaceholder] = useState("I'm Looking For.........");
  const [isopen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isopen);
  };

  // Handler to update the placeholder based on the selected option
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    // Change placeholder based on the selected option
    if (selectedValue === "Categories") {
      setPlaceholder("Click here to choose your Categories");
    } else if (selectedValue === "HSN Code") {
      setPlaceholder("Click here to select HSN");
    } else if (selectedValue === "Products") {
      setPlaceholder("Type the product name that you want to search");
    } else if (selectedValue === "Services") {
      setPlaceholder("Type the service name that you want to search");
    } else if (selectedValue === "Others") {
      setPlaceholder("Type your keywords that you want to .....");
    } else {
      setPlaceholder("I'm Looking For.........");
    }
  };
  // Separate state for each NavDropdown
  const [showSignIn, setShowSignIn] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showPostReq, setShowPostReq] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);

  const handleSignInMouseEnter = () => setShowSignIn(true);
  const handleSignInMouseLeave = () => setShowSignIn(false);

  const handleRegisterMouseEnter = () => setShowRegister(true);

  const handleRegisterMouseLeave = () => setShowRegister(false);

  const handleServicesMouseEnter = () => setShowServices(true);
  const handleServicesMouseLeave = () => setShowServices(false);

  const handleBenefitsMouseEnter = () => setShowBenefits(true);
  const handleBenefitsMouseLeave = () => setShowBenefits(false);

  const handleBusinessMouseEnter = () => setShowBusiness(true);
  const handleBusinessMouseLeave = () => setShowBusiness(false);

  const handlePostReqMouseEnter = () => setShowPostReq(true);
  const handlePostReqMouseLeave = () => setShowPostReq(false);
  return (
    <>
      {/* Mobile view */}
      <div className="sticky top-0 z-50 bg-white navb lg:hidden pt-10 pb-5">
        <nav className="container px-6 flex justify-between items-center">
          {/* Logo */}
          <div>
            <img src={logo} className="w-20" alt="ZeroMiddleMan Logo" />
          </div>

          {/* Sign In Button and Menu Icon */}
          <div className="flex space-x-2 items-center">
            <button className="bg-white border border-black rounded-full px-5 py-1">
              Sign In
            </button>

            {/* Menu Toggle Button */}
            <button onClick={toggleNavbar}>
              {isopen ? <ImCancelCircle /> : <IoMenu />}
            </button>
          </div>
        </nav>

        {/* Search Bar */}
        <div className="border border-black rounded-lg mx-6 my-4 flex relative">
          <form className="w-full">
            <div className="flex items-center">
              <input
                className="w-full m-0.5 px-3 py-2 outline-none"
                type="text"
                placeholder={placeholder} // Set the placeholder dynamically
              />
              <button className="bg-blood text-white rounded-lg p-2 me-1">
                <IoIosSearch />
              </button>
            </div>
          </form>
        </div>

        {/* Collapsible Menu */}
        {isopen && (
          <ul className="flex flex-col items-center space-y-5 w-full">
            <li>
              <a href="#" className="navlinks">
                Home
              </a>
            </li>
            <li>
              <a href="#" className="navlinks">
                Services
              </a>
            </li>
            <li>
              <a href="#" className="navlinks">
                About
              </a>
            </li>
            <li>
              <a href="#" className="navlinks">
                Contact
              </a>
            </li>
            <li>
              <a href="#" className="navlinks">
                Help
              </a>
            </li>
            <li>
              <a href="#" className="navlinks">
                Gallery
              </a>
            </li>
            <li>
              <a href="#" className="navlinks">
                Team
              </a>
            </li>
          </ul>
        )}
      </div>

      {/* lg device */}
      <div className="sticky top-0 bg-white z-50 ">
        <div className="hidden lg:flex   justify-between ">
          <div className="flex justify-center w-full ">
            <div className="px-5  border-r-2 border-gray-200">
              <a href="">
                <img
                  src={logo}
                  // className="zmm-logo-img img-fluid"
                  alt="ZeroMiddleMan Logo"
                />
              </a>
            </div>
            <div className="py-2 w-full justify-center">
              <div className="flex border-3 mx-1  h-12 border-b-2 border-gray-200 justify-between">
                <div className="hidden lg:flex w-1/4 h-1 gap-4 pe-10 flex-col">
                  <div className="flex h-1 items-center pt-2  gap-x-1">
                    <a
                      className="flex items-center"
                      href="tel:+91 8111 010 010"
                      target="_blank"
                    >
                      <span className="hover:text-blood">
                        <FaPhoneAlt />
                      </span>
                      <span className="hover:text-blood"> +91 8111 010 010</span>
                    </a>
                  </div>
                  <div className="flex items-center gap-1">
                    <a
                      className="flex items-center"
                      href="mailto:sales@zeromiddleman.com"
                      target="_blank"
                    >
                      <span className="text-[1.5rem] hover:text-blood">
                        <MdEmail />
                      </span>
                      <span className="hover:text-blood">sales@zeromiddleman.com</span>
                    </a>
                  </div>
                </div>

                <ul className=" hidden lg:flex w-full justify-between font-medium   items-center">
                  <li>
                    <Link to={"/"}> <span className="text-3xl hover:text-blood"><IoHome /></span> </Link>
                  </li>
                  <li>
                    <Link className="hover:text-blood" to={"/listing-service"}>Listing services</Link>
                  </li>
                  <li>
                    <Link className="hover:text-blood" to={"/liaision-service"}>Liaison services</Link>
                  </li>
                  <li>
                    <Link className="hover:text-blood" to={"/directory-services"}>Directory services</Link>
                  </li>
                  <li>
                    <Link className="hover:text-blood" to={"#"}>
                      Company incorporation services
                    </Link>
                  </li>
                  <li>
                    <Link className="hover:text-blood" to={"directory-services"}>
                      New facility set-up services
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="flex justify-between gap-2 p-3">
                <div>
                  <form>
                    <div className="hidden lg:flex w-[15vw] p-2 justify-evenly border rounded-3xl bg-white    border-oceanBlue">
                      <div className="bg-bloo">
                        <input type="radio" name="choose" value="Option 1" />
                        <label className="ps-1 text-oceanBlue">Buyer</label>
                      </div>
                      <div className="">
                        <input type="radio" name="choose" value="Option 2" />
                        <label className="ps-1 text-oceanBlue">Seller</label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="relative border-[1px] border-black rounded-full flex  w-[50vw]">
                  <form className="">
                    <div className="flex pt-1">
                      <div className="flex ps-3">
                        <div>
                          <select
                            className="border-none outline-none"
                            name=""
                            id=""
                            onChange={handleSelectChange}
                          >
                            <option value="Products">Products</option>
                            <option value="Categories">Categories</option>
                            <option value="HSN Code">HSN Code</option>
                            <option value="Services">Services</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                        <div className=" pb-3 ps-3 flex w-[300px] ">
                          <input
                            className="w-[300px] outline-none"
                            type="text"
                            placeholder={placeholder} // Set the placeholder dynamically
                          />
                        </div>
                      </div>
                      <div className="flex bg-blood rounded-full ms-2 p-[6px] px-3 absolute right-0.5 bottom-[2px]">
                        <div className="pt-1 text-white">
                          <IoIosSearch />
                        </div>

                        <button className="text-white">Search</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  <div className="hidden lg:flex col-lg-3  header-main-btns   gap-2">
                    {/* Sign In Dropdown */}
                    <div className="relative group sign-in-btn text-center border border-gray-800 bg-white text-gray-800 rounded-full px-8 py-2 font-bold cursor-pointer hover:bg-gray-800 hover:text-white">
                      <span>Login</span>
                      <div className="absolute left-[10px] top-9 hidden group-hover:block bg-white border border-gray-300 rounded-lg mt-2 w-[250px] shadow-lg z-10">
                        <a
                          href="#seller-dashboard"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Seller Dashboard
                        </a>
                        <a
                          href="#buyer-dashboard"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Buyer Dashboard
                        </a>
                        <a
                          href="#liaison-dashboard"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Liaison Dashboard
                        </a>
                        <a
                          href="#contact-directory"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Contact Directory Buyer
                        </a>
                        <a
                          href="#dashboard"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Dashboard
                        </a>
                      </div>
                    </div>

                    {/* Register Dropdown */}
                    <div className="relative group register-btn text-center bg-blood text-white hover:bg-blood-gradient rounded-full px-8 py-2 font-bold cursor-pointer">
                      <span>Register</span>
                      <div className="absolute right-11 top-9 hidden group-hover:block bg-white border border-gray-300 rounded-lg mt-2 w-[250px] shadow-lg z-10">
                        <a
                          href="#seller-registration"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Seller Registration
                        </a>
                        <a
                          href="#buyer-registration"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Buyer Registration
                        </a>
                        <a
                          href="#liaison-registration"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Liaison Registration
                        </a>
                        <a
                          href="#contact-directory"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Contact Directory Buyer
                        </a>
                        <a
                          href="#registration"
                          className="block px-4 py-2 hover:bg-gray-100 text-black"
                        >
                          Registration
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <div className="border-t-2 border-gray-200 flex justify-center">
          <div className="hidden w-full lg:flex justify-center">
            <div className="flex justify-center items-center gap-2 py-1 mx-auto">
              {/* Navbar Section */}
              <nav className="flex w-full justify-between">
                <div className="flex">
                  <ul className="flex w-full items-center justify-between space-x-8 mx-auto">
                    {/* Services Dropdown */}
                    <div
                      className="relative"
                      onMouseEnter={handleServicesMouseEnter}
                      onMouseLeave={handleServicesMouseLeave}
                    >
                      <button className="text-black hover:text-blood">
                        Services
                      </button>
                      {showServices && (
                        <ul className="absolute bg-white w-[400px] shadow-lg text-black p-5 space-y-2">
                          <li>
                            <Link to={"listing-service"}>Listing services</Link>
                          </li>
                          <li>
                            <Link to={"liaision-service"}>
                              Liaison services
                            </Link>
                          </li>
                          <li>
                            <Link to={"/directory-services"}>
                              Directory services
                            </Link>
                          </li>
                          <li>
                            <a href="#action/3.6">
                              Company incorporation services
                            </a>
                          </li>
                          <li>
                            <a href="#action/3.6">
                              New facility setup services
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  
                      <button className="text-black hover:text-blood">
                        Seller Registration
                      </button>
                     
                      <button className="text-black hover:text-blood">
                        Buyer Registration
                      </button>

                    {/* Post Your Requirements Dropdown */}
                    <div
                      className="relative"
                      onMouseEnter={handlePostReqMouseEnter}
                      onMouseLeave={handlePostReqMouseLeave}
                    >
                      <button className="text-black hover:text-blood">
                        Post your Requirements
                      </button>
                      {showPostReq && (
                        <ul className="absolute w-[300px] bg-white shadow-lg text-black p-5 space-y-2">
                          <li className="hover:text-blood">
                            <a href="#action/3.1">
                              Post your product requirements
                            </a>
                          </li>
                          <li>
                            <a href="#action/3.2">
                              Post your liaison requirements
                            </a>
                          </li>
                          <li>
                            <a href="#action/3.3">
                              Post your service requirements
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                    {/* Add your other dropdowns here following the same pattern */}
                    <button className="bg-gradient-to-r from-[#D0236C] via-[#9D1B51] to-[#6A1237] transition-all ease-out duration-3000 animate-circle w-[250px] h-[45px] mt-2 mb-2 ml-2">
                      Explore B2B Buyer Directory
                    </button>
                    <button className="bg-gradient-to-r from-[#D0236C] via-[#9D1B51] to-[#6A1237] transition-all ease-out duration-3000 animate-circle w-[250px] h-[45px] mt-2 mb-2 ml-2">
                      Explore B2B Seller Directory
                    </button>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
