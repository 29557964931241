import React from 'react'
import './OurProcess.css'
import processArrow from '../../../../../assetss/localCoordination/ourProcessArrow.png'
const OurProcess = () => {
  const OurProcess = [
    {
      title: "Identifying Reliable partners",
      description: "We conduct through research to identify reputable local suppliers that align with our quality standards and business values.",
    },
    {
      title: "Identifying Reliable partners",
      description: "We conduct through research to identify reputable local suppliers that align with our quality standards and business values.",
    },
    {
      title: "Identifying Reliable partners",
      description: "We conduct through research to identify reputable local suppliers that align with our quality standards and business values.",
    },
    {
      title: "Identifying Reliable partners",
      description: "We conduct through research to identify reputable local suppliers that align with our quality standards and business values.",
    }
  ]
    ;
  return (
    <div>
      <div className='our-process flex justify-center items-center'>
      <div className=" xl:max-w-[1400px] xl:h-[850px] lg:h-[750px] h-[800px] lg:max-w-[1200px] py-5">
        <h1 className='text-white text-center text-3xl font-semibold'>Our Process</h1>
        <div className='py-5 relative xl:w-[1400px] lg:w-[1200px] px-5 flex flex-col lg:display-[block] gap-5'>
            <div className='process-card xl:w-[700px] lg:w-[600px]  flex xl:gap-5 lg:gap-3  lg:absolute '>
              <div className='flex flex-col justify-center xl:w-[500px] lg:w-[450px] bg-gray-400 p-3 rounded-lg'>
              <h1 className='text-center text-xl font-semibold'>Identifying Reliable partners</h1>
              <span className='text-center'>We conduct through research to identify reputable local suppliers that align with our quality standards and business values.</span>
              </div>
              <div className='hidden lg:block process-arrow pt-5'>
                <img src={processArrow} alt="" className='xl:h-[100px] xl:w-[250px] lg:h-[100px] lg:w-[140px]' />
              </div>
            </div>
            <div className='process-card xl:w-[700px] lg:w-[600px] flex xl:gap-5 lg:gap-3   lg:absolute  xl:top-[190px] xl:left-[285px] lg:top-[180px] lg:left-[250px]'>
              <div className='flex flex-col justify-center xl:w-[500px] lg:w-[450px] bg-gray-400 p-3 rounded-lg'>
              <h1 className='text-center text-xl font-semibold'>Identifying Reliable partners</h1>
              <span className='text-center'>We conduct through research to identify reputable local suppliers that align with our quality standards and business values.</span>
              </div>
              <div className='hidden lg:block process-arrow pt-5'>
                <img src={processArrow} alt="" className='xl:h-[100px] xl:w-[250px] lg:h-[100px] lg:w-[140px]' />
              </div>
            </div>
            <div className='process-card xl:w-[700px] lg:w-[600px]  flex xl:gap-5 lg:gap-3  lg:absolute xl:top-[360px] xl:left-[570px] lg:top-[340px] lg:left-[500px]'>
              <div className='flex flex-col justify-center xl:w-[500px] lg:w-[450px] bg-gray-400 p-3 rounded-lg'>
              <h1 className='text-center text-xl font-semibold'>Identifying Reliable partners</h1>
              <span className='text-center'>We conduct through research to identify reputable local suppliers that align with our quality standards and business values.</span>
              </div>
              <div className=' hidden lg:block process-arrow pt-5'>
                <img src={processArrow} alt="" className='xl:h-[100px] xl:w-[250px] lg:h-[100px] lg:w-[140px]' />
              </div>
            </div>
            <div className='process-card  lg:w-[500px] flex gap-5   lg:absolute xl:top-[530px] xl:left-[850px] lg:top-[510px] lg:left-[730px]'>
              <div className='flex flex-col justify-center lg:w-[450px] bg-gray-400 p-3 rounded-lg'>
              <h1 className='text-center text-xl font-semibold'>Identifying Reliable partners</h1>
              <span className='text-center'>We conduct through research to identify reputable local suppliers that align with our quality standards and business values.</span>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default OurProcess
