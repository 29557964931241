import React from 'react';
import './PaymentFollowBanner.css'

const PaymentFollowBanner = () => {
    const bannerTitle = "Payment Follow up & Personal Visits";
    const splitTitle = (title) => {
        const midIndex = Math.ceil(title.length / 2);
        const title1 = title.slice(0, midIndex);
        const title2 = title.slice(midIndex);
        return { title1, title2 }
    }
    const { title1, title2 } = splitTitle(bannerTitle);
    const PaymentFollowUPBanner = [
        {
            title1: title1,
            title2: title2,
            description: "At ZMM, we prioritize seamless transactions and quality assurance in every aspect of our import-export services. Our dedicated team is committed to ensuring that your experience is smooth and hassle-free.",
        }
    ]
    return (
        <div>
            <div>
                <div className="payment-follow-banner flex justify-center">
                    <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] h-[469px] flex items-center'>
                        {PaymentFollowUPBanner.map((banner, index) => (
                            <div key={index} className='flex flex-col items-center justify-center text-white py-3'>
                                <h1 className='lg:text-3xl text-xl text-center font-semibold'><span className='text-red-600 '>{banner.title1}</span>{banner.title2}</h1>
                                <span className='text-center leading-loose px-[60px] py-3'>{banner.description}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentFollowBanner  
