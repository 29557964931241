import React from 'react';
import './AfterSalesBanner.css';

const AfterSalesBanner = () => {
    const bannerTitle = "After Sales Support";
    const splitTitle = (title) => {
        const midIndex = Math.ceil(title.length / 2);
        const title1 = title.slice(0, midIndex);
        const title2 = title.slice(midIndex);
        return { title1, title2 }
    }
    const { title1, title2 } = splitTitle(bannerTitle);
    const AfterSalesBanner = [
        {
            title1: title1,
            title2: title2,
            description: "At ZMM, we believe that our commitment to our clients extends well beyond the initial sale. Our dedicated after sales and service support ensures that you receive comprehensive assistance tailored to your needs. Whether you require product installation, training or troubleshooting, our experienced team is here to help.",
        }
    ]
  return (
    <div>
      <div className="after-sales-banner flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] h-[469px] flex items-center'>
        {AfterSalesBanner.map((banner, index) => (
                        <div key={index} className='flex flex-col items-center justify-center text-white py-3'>
                            <h1 className='lg:text-3xl text-xl text-center font-semibold'><span className='text-red-600 '>{banner.title1}</span>{banner.title2}</h1>
                            <span className='text-center leading-loose lg:px-[60px] py-3'>{banner.description}</span>
                        </div>
                    ))}
        </div>
      </div>
    </div>
  )
}

export default AfterSalesBanner
