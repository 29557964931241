import React, { useState, useEffect } from 'react';
import person1 from '../../../../assetss/TestmonialSlides/person1.png';
import person2 from '../../../../assetss/TestmonialSlides/person2.png';
import person3 from '../../../../assetss/TestmonialSlides/person3.png';
import person4 from '../../../../assetss/TestmonialSlides/person4.png';
import './Testimonials.css';

const Testimonials = () => {
  const testimonials = [
    {
      image: person1,
      name: "Hannah Schmitt",
      text: "Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.",
    },
    {
      image: person2,
      name: "John Doe",
      text: "Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.",
    },
    {
      image: person3,
      name: "Aadhya",
      text: "Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.",
    },
    {
      image: person4,
      name: "Vishwajeet",
      text: "Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.",
    }
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const nextTestimonial = () => {
    setCurrentTestimonial((prevTestimonial) =>
      prevTestimonial === testimonials.length - 1 ? 0 : prevTestimonial + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prevTestimonial) =>
      prevTestimonial === 0 ? testimonials.length - 1 : prevTestimonial - 1
    );
  };

  // Automatically slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextTestimonial, 5000);
    return () => clearInterval(interval);
  }, [currentTestimonial]);

  return (
    <div className=' testimonials flex justify-center'>
    <div className="xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] mx-auto bg-cover bg-center  lg:h-[600px] h-[700px] text-white lg:py-12 py-6 px-5 flex flex-col  items-center testimonials">
      <h1 className="text-center text-4xl font-bold mb-4">Testimonials</h1>
      <h3 className="text-center text-xl mb-8">WHAT OUR CLIENTS SAY ABOUT OUR DIGITAL SERVICES</h3>
      <div className="flex justify-center">
        <div className=" text-gray-800 w-full max-w-4xl lg:p-8  flex flex-col items-center justify-center transition-transform ease-in-out duration-500  ">
          <div className="w-full flex flex-col items-center justify-center gap-5  lg:h-[300px] h-[410px] shadow-lg rounded-lg bg-white/90 text-center">
            {/* Centering the image on X-axis */}
            <div className="w-full flex justify-center"> 
              <img 
                src={testimonials[currentTestimonial].image}
                className="w-[100px] h-[100px] rounded-full shadow-md object-cover border border-solid border-blue-400" 
                alt={testimonials[currentTestimonial].name} 
              />
            </div>
            <div className="w-3/4">
              <h4 className="text-2xl font-bold mb-3">{testimonials[currentTestimonial].name}</h4>
              <p className="text-center italic text-gray-700">"{testimonials[currentTestimonial].text}"</p>
            </div>
          </div>
          <div className="flex justify-between w-16 mt-8 text-white">
            <button className="text-3xl text-gray-600 hover:text-black" onClick={prevTestimonial}>❮</button>
            <button className="text-3xl text-gray-600 hover:text-black" onClick={nextTestimonial}>❯</button>
          </div>
          <div className="mt-4 flex justify-center space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full transition-colors duration-500 ${currentTestimonial === index ? 'bg-black' : 'bg-gray-400'}`}
                onClick={() => setCurrentTestimonial(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Testimonials;
