import React from 'react';
import arrow from '../../src/assetss/arrow.png';
import arrow1 from '../../src/assetss/arrow1.png';
import "./Buyer.css";

export const Buyer = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-white max-w-[1400px] mx-auto py-6 px-5 relative">
      <div className="text-center text-xl mb-6">
        <h3 className="text-blood text-3xl font-semibold pb-3">Are you Buyer?</h3>
        <p className="text-xl">Start your search today!</p>
      </div>

      <div className="border border-oceanBlue p-6 rounded-lg text-center mb-10 w-full max-w-lg">
        <h2 className="text-oceanBlue font-semibold mb-2">
          Find Products & Services source with precision
        </h2>       
        <p className="text-oceanBlue">
          Search by category, product name, HSN, Service Name and Location (Country / State and City).
        </p>
      </div>

      {/* Arrow - hidden on mobile, visible on lg and above */}
      {/* <div className="hidden lg:flex justify-between w-full max-w-4xl mb-4">
        <img src={arrow} alt="arrow" className="hidden lg:block arrow" />
        <img src={arrow} alt="arrow" className="hidden lg:block arrow2" />
      </div> */}

      {/* Responsive layout for cards */}
      <div className="flex flex-col space-y-6 lg:flex-row lg:space-x-16 lg:space-y-0 justify-between w-full max-w-[1400px]">
        <div className="border border-oceanBlue p-6 rounded-lg text-center flex-1">
          <h2 className="text-oceanBlue font-semibold mb-2">Connect with Suppliers</h2>
          <p className='text-oceanBlue'>Get quotes and connect with verified Manufacturer/Producer/Farmers directly.</p>
        </div>
        <div className="border border-oceanBlue p-6 rounded-lg text-center flex-1">
          <h2 className="text-oceanBlue font-semibold mb-2">Explore Global Sourcing</h2>
          <p className='text-oceanBlue'>Discover opportunities from sources around the world.</p>
        </div>
      </div>

      {/* Second arrow - hidden on mobile, visible on lg and above */}
      {/* <div className="hidden lg:flex justify-center w-full max-w-4xl">
        <img src={arrow1} alt="arrow1" className="hidden lg:block mb-4 arrow1" /> 
      </div> */}
    </div>
  );
};
