import React from 'react';
import icon1 from '../../../../../assetss/afterSalesSupport/icons/Bright Mind.png';
import icon2 from '../../../../../assetss/afterSalesSupport/icons/Performance Macbook.png';
import icon3 from '../../../../../assetss/afterSalesSupport/icons/Handshake.png';
import icon4 from '../../../../../assetss/LocalNeeds/icons/Best Seller.png'

const BenifitsOfServices = () => {
  return (
    <div>
      <div className="benifits-after-sales flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w[1279px] lg:w-[full] py-[30px]'>
            <div className='container mx-auto'>
            <h1 className='text-center text-3xl font-bold p-2'>Benefits of Our Permanent Local Liaison Services</h1>
            <div className='flex flex-wrap lg:flex-nowrap gap-[20px] py-[20px]'>
                <div className='lg:w-1/4 md:w-1/3  sm:w-full w-full p-[20px] '>
                <div className='flex flex-col items-center gap-[10px] border border-[#0C92CC] rounded-lg p-3 lg:min-h-[320px] xl:min-h-[240px] shadow-lg'>
                    <img src={icon1} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl font-semibold text-center'>Local Expertise</h1>
                        <p className='text-center'>Gain access to professionals who understand the local market and can provide tailored advice.</p>
                    </div>
                </div>
                </div>
                <div className='lg:w-1/4 md:w-1/3  sm:w-full w-full p-[20px]'>
                <div className='flex flex-col items-center gap-[10px] border border-[#0C92CC] rounded-lg p-3 lg:min-h-[320px] xl:min-h-[240px] shadow-lg'>
                    <img src={icon2} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl font-semibold text-center'>Improved Efficiency </h1>
                        <p className='text-center'>Streamline operations with a dedicated liaison who can quickly address issues and facilitate processes.</p>
                    </div>
                </div>
                </div>
                <div className='lg:w-1/4 md:w-1/3  sm:w-full w-full  p-[20px]'>
                <div className='flex flex-col items-center gap-[10px] border border-[#0C92CC] rounded-lg p-3 lg:min-h-[320px] xl:min-h-[240px] shadow-lg'>
                    <img src={icon3} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl font-semibold text-center'>Cost Savings</h1>
                        <p className='text-center'>Reduce the risks associated with misunderstandings and compliance issues, ultimately saving you Ɵme and
                        money.</p>
                    </div>
                </div>
                </div>
                <div className='lg:w-1/4 md:w-1/3  sm:w-full w-full  p-[20px]'>
                <div className='flex flex-col items-center gap-[10px] border border-[#0C92CC] rounded-lg p-3 lg:min-h-[320px] xl:min-h-[240px] shadow-lg'>
                    <img src={icon4} alt="icon" className='w-[80px] h-[80px]' />
                    <div>
                        <h1 className='text-xl text-center font-semibold'>Enhanced Competitiveness</h1>
                        <p className='text-center'>Leverage local knowledge to make strategic decisions that position your business for success.</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default BenifitsOfServices
