import React from 'react';
import vector1 from '../../../../../assetss/realTimeUpdate/vector1.png'

const StayConnected = () => {
  return (
    <div>
      <div className="stay-connected flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] py-[20px]'>
            <h1 className='text-3xl text-center font-bold'>Stay Connected</h1>
            <div className='flex flex-wrap gap-[20px] lg:flex-nowrap items-center'>
              <div  className='lg:w-2/3 md:w-1/2 sm:w-full w-full'>
              <div className='p-3 flex  flex-col gap-[10px]'>
              <h1 className='text-xl font-semibold '>Source company insights</h1>
              <p className='lg:text-right text-justify'>Get detailed information about our trusted suppliers, including their certification, production capacities and quality control measures. We keep you informed about any changes or updates to our sourcing partners, ensuring you always know who you are working with.</p>
              </div>
              <div className='p-3 flex  flex-col gap-[10px]'>
                <h1 className='text-xl font-semibold'>Order Tracking</h1>
                <p className='lg:text-right  text-justify'>Monitor your orders every step of the way. Our State-of-the-art tracking system allows you to see the status of your shipments in real time, from placement to delivery. You’ll receive notification on key milestones, including dispatch, transits and arrival times.</p>
              </div>
              </div>
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full'>
                  <img src={vector1} alt="" className='w-[full] h-[auto]' />
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default StayConnected
