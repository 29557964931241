import React, { useState } from "react";

const products = [
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable Energy" },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable Energy" },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable Energy" },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable Energy" },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable Energy" },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable " },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable " },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable " },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable " },
  { imgSrc: require("../assetss/Vector.png"), description: "Renewable " }
];

function Prod({ product }) {
  return (
    <div className="flex flex-col h-full mx-3 lg:mx-5">
      <div className="bg-white shadow-lg rounded-full p-5 lg:p-10 w-full m-4 flex justify-center flex-col relative">
        <div className="flex-col items-center w-[60px]">
          <img className="ps-5" src={product.imgSrc} alt="Product" />
          <h5 className="flex justify-center text-center text-[12px] lg:text-[14px]">
            {product.description}
          </h5>
        </div>
      </div>
    </div>
  );
}

export const TrendingSection = () => {
  const [currentIndexRow1, setCurrentIndexRow1] = useState(0);
  const [currentIndexRow2, setCurrentIndexRow2] = useState(0);

  // Responsive number of cards based on screen size
  const getCardsPerPage = () => {
    if (window.innerWidth < 640) return 2; // mobile
    if (window.innerWidth < 768) return 3; // tablet
    if (window.innerWidth < 1024) return 4; // lg devices
    return 5; // larger screens
  };

  const cardsPerPage = getCardsPerPage();

  const nextSlideRow1 = () => {
    if (currentIndexRow1 < products.length - cardsPerPage) {
      setCurrentIndexRow1(currentIndexRow1 + cardsPerPage);
    }
  };

  const prevSlideRow1 = () => {
    if (currentIndexRow1 > 0) {
      setCurrentIndexRow1(currentIndexRow1 - cardsPerPage);
    }
  };

  const nextSlideRow2 = () => {
    if (currentIndexRow2 < products.length - cardsPerPage) {
      setCurrentIndexRow2(currentIndexRow2 + cardsPerPage);
    }
  };

  const prevSlideRow2 = () => {
    if (currentIndexRow2 > 0) {
      setCurrentIndexRow2(currentIndexRow2 - cardsPerPage);
    }
  };

  return (
    <div className="relative flex flex-col bg-cover bg-no-repeat p-4"
      style={{ backgroundImage: `url(${require('../assetss/trending.png')})` }}>
      <h1 className="text-center text-2xl lg:text-4xl font-bold mb-4 text-white">
        Trending Categories
      </h1>

      {/* Row 1 */}
      <div className="flex justify-center ">
      <div className="">
      <div className="max-w-[1400px]">
      <div className="relative  flex items-center justify-center mb-8">
        <button className="absolute left-0 text-2xl p-2 rounded-full text-3xl text-white hover:text-black" onClick={prevSlideRow1}>
          ❮
        </button>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 gap-3 lg:gap-5">
          {products.slice(currentIndexRow1, currentIndexRow1 + cardsPerPage).map((product, index) => (
            <Prod key={index} product={product} />
          ))}
        </div>
        <button className="  text-2xl p-2 rounded-full text-3xl  text-white hover:text-black" onClick={nextSlideRow1}>
          ❯
        </button>
      </div>

      <h1 className="text-center text-2xl lg:text-4xl font-bold mb-4 text-white">
        Trending Services
      </h1>

      {/* Row 2 */}
      <div className="relative flex items-center justify-center">
        <button className="absolute left-0 text-2xl p-2 rounded-full text-3xl  text-white hover:text-black" onClick={prevSlideRow2}>
          ❮
        </button>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 gap-3 lg:gap-5">
          {products.slice(currentIndexRow2, currentIndexRow2 + cardsPerPage).map((product, index) => (
            <Prod key={index} product={product} />
          ))}
        </div>
        <button className=" text-2xl p-2 rounded-full text-3xl text-white  hover:text-black" onClick={nextSlideRow2}>
          ❯
        </button>
      </div>
      </div>
      </div>
      </div>
     
    </div>
  );
};
