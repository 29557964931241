import React, { useState } from 'react';
import Modal from 'react-modal'; // Import react-modal
import logo from '../../../../assetss/ZeroMiddleMan Logo 3.png';
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosSearch } from "react-icons/io";
import { IoFilterSharp, IoSearch } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa6";


// Modal Styles
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '400px',
    height: '80%',
  },
};

const Header = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modal2IsOpen, setModal2IsOpen]= useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModal2 = ()=>{
    setModal2IsOpen(true);
  };
  const closeModal2 = ()=> {
    setModal2IsOpen(false);
  }

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    if (currentSlide < 1) {
      setCurrentSlide(currentSlide + 1);
    }
  }

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  }

  return (
    <div className='w-full px-[20px]'>
      {/* Desktop View */}
      <div className=" lg:block hidden container mx-auto max-w-custom">
        <div className='flex flex-row'>
          {/* Logo Column */}
          <div className='flex justify-center items-center border-r border-gray-200 w-1/6'>
            <NavLink to={'/'}>
              <img src={logo} className='w-full' alt="ZeroMiddleMan Logo" />
            </NavLink>
          </div>
          {/* Phone Number and Menu Column */}
          <div className='w-5/6'>
            <div className='flex justify-between items-center p-2 border-b border-gray-200'>
              {/* Phone Section */}
              <div className='flex items-center text-gray-600'>
                <MdOutlinePhoneInTalk className='text-xl mr-1' />
                <span>+1 222-555-33-99</span>
              </div>
              {/* Menu Section */}
              <div className="flex justify-end">
                <ul className="flex space-x-4">
                  <li><NavLink to=''>Home</NavLink></li>
                  <li><NavLink>Services</NavLink></li>
                  <li><NavLink>About</NavLink></li>
                  <li><NavLink>Contact</NavLink></li>
                  <li><NavLink>Help</NavLink></li>
                  <li><NavLink>Gallery</NavLink></li>
                  <li><NavLink>Team</NavLink></li>
                </ul>
              </div>
            </div>
            <div className="flex justify-between items-center p-3">
              {/* Radio Buttons */}
              <div className="flex items-center border-r border-gray-200">
                <form className="flex space-x-4 p-2 border border-[#0C92CC] rounded-[30px]">
                  <div className="flex items-center text-[#0C92CC]">
                    <input type="radio" name="userType" value="Buyer" />
                    <label className="ml-2">Buyer</label>
                  </div>
                  <div className="flex items-center text-[#0C92CC]">
                    <input type="radio" name="userType" value="Seller" />
                    <label className="ml-2">Seller</label>
                  </div>
                </form>
              </div>

              {/* Search Bar */}
              <div className="flex xl:w-[600px] mx-4 border border-[#333333] rounded-[30px]">
                <form className="flex w-full">
                  <select className="py-2 px-4 border-none bg-transparent rounded-l-lg outline-none">
                    <option value="">Products</option>
                    <option value="HSN Code">HSN Code</option>
                    <option value="Services">Services</option>
                    <option value="Others">Others</option>
                  </select>
                  <input
                    type="text"
                    placeholder="I'm Looking For........."
                    className="w-full py-2 px-4 border-l border-gray-400 outline-none"
                  />
                  <button className="flex items-center text-white bg-red-600 px-4 py-2 rounded-[30px]">
                    <IoIosSearch className="text-xl" /> Search
                  </button>
                </form>
              </div>

              {/* Sign In and Register Buttons */}
              <div className="flex space-x-4">
                <button className="border border-gray-400 text-gray-600 px-[15px] py-2 rounded-[30px]">
                  Sign in
                </button>
                <button className="bg-red-600 text-white px-5 py-2 rounded-[30px]">
                  Register
                </button>
              </div>
            </div>

          </div>
        </div>
        {/* Services List */}
        <div className='py-2'>
        <ul className='flex justify-center space-x-8'>
          <li>
            <NavLink 
              to="local-coordination" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              Local Co-ordination
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="quality-verification" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              Quality Verification
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="documentation-certification" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              Documentation & Certification
            </NavLink>
          </li>
          <li className='text-center'>
            <NavLink 
              to="realtime-update" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              Real time Update and Tracking of Orders in Person
            </NavLink>
          </li>
          <li className='text-center'>
            <NavLink 
              to="payment-follow-up" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              Payment Follow Up & Personal Visits
            </NavLink>
          </li>
          <li className='text-center'>
            <NavLink 
              to="after-sales-support" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              After Sales & Support
            </NavLink>
          </li>
          <li className='text-center'>
            <NavLink 
              to="local-needs" 
              className={({ isActive }) => isActive ? "text-red-600" : "text-black"}
            >
              Permanent Local & Liaison for all needs
            </NavLink>
          </li>
        </ul>
        </div>
      </div>
      {/* Mobile View */}
      <div className="block lg:hidden">
        <div className="container mx-auto flex justify-between items-center py-4 px-4 sm:px-6 lg:px-8">
          <img src={logo} alt="ZeroMiddleMan Logo" className='w-1/2 sm:w-1/3' /> {/* Adjust width on different screen sizes */}
          <div className="flex items-center space-x-4 px-3">
            <button className='text-sm sm:text-base border border-gray-400 px-4 py-2 rounded-full'>
              Sign In
            </button>
            <button onClick={openModal}>
              <RxHamburgerMenu className='text-2xl' />
            </button>
          </div>
        </div>
        <div className="container mx-auto py-3 px-4 sm:px-6 lg:px-8">
          <form className='flex items-center border border-gray-400 rounded-full mx-2'>
            <a onClick={openModal2}>
            <IoFilterSharp className='text-4xl sm:text-4xl px-2' />
            </a>
            <input
              type="text"
              placeholder="Products, HSN code, Manufacturer"
              className='w-full py-2 px-2 border-none text-sm sm:text-base'
            />
            <button className='bg-red-600 px-5 py-3 sm:px-5 sm:py-3 rounded-full'>
              <IoSearch className='text-white text-lg sm:text-xl' />
            </button>
          </form>
        </div>
      </div>


      {/* Modal for Hamburger Menu */}


      {/* Modal for Hamburger Menu */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ffffff51', // semi-transparent white
            zIndex: 1000,
          },
          content: {
            position: 'absolute',
            top: '200px',
            left: '0',
            right: '0',
            bottom: '0',
            border: 'none',
            padding: '20px',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            width: '90%', // Responsive width, covering 90% of the screen
            maxWidth: '400px', // Max width to prevent it from getting too wide
            margin: '0 auto', // Center it horizontally
          },
        }}
        contentLabel="Mobile Menu Modal"
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-md font-bold flex items-center gap-2">
            <MdOutlinePhoneInTalk />
            +1 222-555-33-99
          </h2>
          <button onClick={closeModal} className="text-red-600 text-3xl">
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* First slide */}
        {currentSlide === 0 && (
          <ul className="space-y-4 flex flex-col justify-center items-center">
            <li><a href="#">Home</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">Help</a></li>
            <li><a href="#">Gallery</a></li>
            <li><a href="#">Team</a></li>
          </ul>
        )}

        {/* Second slide */}
        {currentSlide === 1 && (
          <ul className="space-y-4 flex flex-col justify-center items-center">
            <li><NavLink to = 'local-coordination' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Local Coordination</NavLink></li>
            <li><NavLink to = 'quality-verification' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Quality Verification</NavLink></li>
            <li><NavLink to = 'documentation-certification' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Documentation and Certification</NavLink></li>
            <li><NavLink to = 'realtime-update' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Real-Time Updates & Tracking of Orders in Person</NavLink></li>
            <li><NavLink to = 'payment-follow-up' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Payment Follow-Up and Personal Visits</NavLink></li>
            <li><NavLink to = 'After-sales-support' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Sales Suppor</NavLink>t</li>
            <li><NavLink to = 'local-needs' className={({ isActive }) => isActive ? "text-red-600" : "text-black"}>Permanent Local Liaison for All Needs</NavLink></li>
          </ul>
        )}

        {/* Navigation Arrows */}
        <div className="flex justify-between items-center mt-6">
          {currentSlide > 0 && (
            <button onClick={prevSlide} className="text-blue-600">
              &larr; Previous
            </button>
          )}
          {currentSlide < 1 && (
            <button onClick={nextSlide} className="text-blue-600 ml-auto">
              Next &rarr;
            </button>
          )}
        </div>
      </Modal>

      {/* Modal for filter option */}
      <Modal
        isOpen={modal2IsOpen}
        onRequestClose={closeModal2}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#ffffff51', // semi-transparent white
            zIndex: 1000,
          },
          content: {
            position: 'absolute',
            top: '200px',
            left: '0',
            right: '0',
            bottom: '0',
            border: 'none',
            padding:'0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            width: '100%', // Responsive width, covering 90% of the screen
            maxWidth: '400px', // Max width to prevent it from getting too wide
            margin: '0  auto', // Center it horizontally
          },
        }}
        contentLabel="Mobile Menu Modal" className='block lg:hidden'
      >
        {/* <div className="flex justify-between items-center mb-4">
          <h2 className="text-md font-bold flex items-center gap-2">
            <MdOutlinePhoneInTalk />
            +1 222-555-33-99
          </h2>
          <button onClick={closeModal} className="text-red-600 text-3xl">
            <IoIosCloseCircleOutline />
          </button>
        </div> */}

        {/* First slide */}
        {/* {currentSlide === 0 && (
          <ul className="space-y-4 flex flex-col justify-center items-center">
            <li><a href="#">Home</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">Help</a></li>
            <li><a href="#">Gallery</a></li>
            <li><a href="#">Team</a></li>
          </ul>
        )} */}

        {/* Second slide */}
        {/* {currentSlide === 1 && (
          <ul className="space-y-4 flex flex-col justify-center items-center">
            <li><a href="#">Local Coordination</a></li>
            <li><a href="#">Quality Verification</a></li>
            <li><a href="#">Documentation and Certification</a></li>
            <li><a href="#">Real-Time Updates & Tracking of Orders in Person</a></li>
            <li><a href="#">Payment Follow-Up and Personal Visits</a></li>
            <li><a href="#">Sales Support</a></li>
            <li><a href="#">Permanent Local Liaison for All Needs</a></li>
          </ul>
        )} */}

        {/* Navigation Arrows */}
        {/* <div className="flex justify-between items-center mt-6">
          {currentSlide > 0 && (
            <button onClick={prevSlide} className="text-blue-600">
              &larr; Previous
            </button>
          )}
          {currentSlide < 1 && (
            <button onClick={nextSlide} className="text-blue-600 ml-auto">
              Next &rarr;
            </button>
          )}
        </div> */}
        <div className='  h-[250px]'>
            <div className='flex gap px-2 border-b-2 '>
              <div className=' flex gap-[154px] py-[10px] '>
              <a href="" className='flex'> <FaArrowLeft className=' text-2xl pe-2'/> Filter</a>
              <a href="" className='text-[#0C92CC]'>Clear Filter</a>
              </div>
            </div>
            <div className='flex flex-wrap '>
              <div className="w-2/4 px-2 border border-r-1 h-[full]">
              <div className='py-[10px]'>
              <form action="" className='text-[#0C92CC] border border-[#0C92CC] rounded-3xl p-2 flex gap-[20px]'>
                <label htmlFor="" className='flex'>
                  <input type="radio"/>
                  <option>Buyer</option>
                </label>
                <label htmlFor="" className='flex'>
                  <input type="radio" />
                  <option>Seller</option>
                </label>
              </form>
              </div>
              <div>
                <a href="">Choose your products</a>
              </div>
              </div>
              <div className='w-2/4 p-[20px]'>
              <form action="" className='flex flex-col gap-[20px]'>
              <label htmlFor="" className='flex gap-[10px]'>
              <input type="radio" />
              <option>HSN Code</option>
              </label>
              <label htmlFor="" className='flex gap-[10px]'>
              <input type="radio" />
              <option>Categories</option>
              </label>
              <label htmlFor="" className='flex gap-[10px]'>
              <input type="radio" />
              <option>Services</option>
              </label>
              <label htmlFor="" className='flex gap-[10px]'>
              <input type="radio" />
              <option>Others</option>
              </label>
              </form>
              
              </div>
            </div>
            <div className='flex justify-end my-3 px-[10px]'>
              <button className='bg-red-600 rounded-lg text-white py-2 px-3'>Search</button>
            </div>
        </div>
      </Modal>






    </div>
  );
};

export default Header;
