import React from 'react';
import { IoIosPeople } from "react-icons/io";
import { AiFillProduct } from "react-icons/ai";
import "./OurVision.css";

const OurVision = () => {
  return (
    <div className='flex justify-center bg-white max-w-[1400px] mx-auto px-5'>
      <div className='max-w-[1400px]'>
        <div className='rounded-3xl relative h-4/5 md:h-[450px] w-[100%] px-5 lg:px-20 bg-cover  mx-auto our-vision'>
          <h1 className='text-3xl text-white font-semibold text-center py-8'>Our Vision</h1>
          <p className='text-white font-medium px-5 lg:px-44 text-center text-sm lg:text-base'>
            We envision a global community where everyone can effortlessly access top-quality services and businesses. Whether you’re searching for a skilled plumber or flight manufacturer, our directory connects you with trusted professionals from.
          </p>
          <div className='flex flex-col sm:flex-row overflow-x-auto lg:overflow-visible space-y-4 sm:space-y-0 sm:space-x-4 pt-10 justify-evenly'>
            <div className='min-w-[250px] h-[165px] lg:h-[180px] lg:w-auto p-7 border backdrop-blur-[3px] border-white rounded-xl'>
              <h1 className='text-white text-6xl flex justify-center'><IoIosPeople /></h1>
              <p className='text-white font-bold text-center'>Verified Buyer</p>
              <p className='text-white text-center text-3xl font-semibold'>86364</p>
            </div>
            <div className='min-w-[250px] h-[165px] lg:h-[180px] lg:w-auto p-7 border backdrop-blur-[3px] border-white rounded-xl'>
              <h1 className='text-white text-6xl flex justify-center'><AiFillProduct /></h1>
              <p className='text-white font-bold text-center'>Verified Seller</p>
              <p className='text-white text-center text-3xl font-semibold'>86364</p>
            </div>
            <div className='min-w-[250px] h-[165px] lg:h-[180px] lg:w-auto p-7 border backdrop-blur-[3px] border-white rounded-xl'>
              <h1 className='text-white text-6xl flex justify-center'><AiFillProduct /></h1>
              <p className='text-white font-bold text-center'>Verified Seller</p>
              <p className='text-white text-center text-3xl font-semibold'>86364</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurVision;
