import React, { useState } from 'react';
import RFQVector from '../../../../assetss/rqf-vector-1.png';
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { CiMail } from "react-icons/ci";
import { FaPeopleCarryBox, FaArrowRight } from "react-icons/fa6";
import RQFFormModal from './RFQFormModal'; // Import the Modal component
import logo from '../../../../assetss/ZeroMiddleMan Logo 3.png';

const RFQSection = () => {
  const [showModal, setShowModal] = useState(false); // Modal state to control visibility

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const requirements = formData.get("requirements");
    console.log("User Requirements:", requirements);
    alert("Requirements submitted!");
    handleCloseModal(); // Close modal after form submission
  };

  return (
    <div className="max-w-[1400px] mx-auto flex flex-col justify-center py-5">
      <h1 className="text-center text-4xl">Tell Us Requirements</h1>
      <div className="flex flex-col lg:flex-row py-2">
        <div className="lg:w-1/2 md:w-1/2 sm:w-full w-full flex items-center justify-center">
          <img src={RFQVector} alt="" className="w-[400px] h-[400px]" />
        </div>
        <div className="lg:w-1/2 md:w-1/2 sm:w-full">
          <div className="py-5 text-center">
            <h3 className="text-lg font-bold">Get your Quotes from multiple sellers</h3>
            <p className="mt-2">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            <div className="mt-5">
              <div className="flex flex-wrap justify-center">
                <div className="flex flex-col items-center justify-center gap-2 w-1/3 lg:p-5 p-3">
                <div className='h-[100px] flex items-center justify-center'>
                  <HiOutlineQuestionMarkCircle className="text-[80px]" />
                  </div>
                  <div>
                  <span className="font-semibold text-base">Tell us what you need</span>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-2 w-1/3 lg:p-5 p-3">
                <div  className='h-[100px] flex items-center justify-center'>
                  <CiMail className="text-[80px]" />
                  </div>
                  <div>
                  <span className="font-semibold text-base">Receive free quotes from sellers</span>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-2 w-1/3 lg:p-5 p-3">
                <div  className='h-[100px] flex items-center justify-center'>
                  <FaPeopleCarryBox className="text-[80px]" />
                  </div>
                  <div>
                  <span className="font-semibold text-base">Seal your deal</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 flex lg:justify-end justify-center">
              <button className="bg-red-600 text-white px-4 py-2 font-semibold rounded-md flex items-center " onClick={handleShowModal}>
                Type Your Requirements <FaArrowRight className="ml-2 text-xl" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for request form */}
      <RQFFormModal show={showModal} handleClose={handleCloseModal}>
        <div className="text-center mb-4">
          <img src={logo} alt="" />
        </div>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <div className="mb-4 w-full">
            <label className="block mb-2">Requirements:</label>
            <textarea name="requirements" required className="border rounded-lg w-full h-32 p-2" />
          </div>
          <div>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 font-semibold rounded-md">Submit</button>
          </div>
        </form>
      </RQFFormModal>
    </div>
  );
}

export default RFQSection;
