import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; 

export const Footer = () => {
  return (
    <div>
      <footer className="bg-[#333333] text-white py-5 text-center ">
        <div className="flex justify-around flex-wrap px-5">
          {/* Useful Links Section */}
          <div className="mb-5">
            <h1 className="flex mb-4 text-lg font-bold">Useful Links</h1>
            <ul className="list-none p-0">
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">About Us</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Contact Us</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Help</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Terms & Conditions</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Privacy & Policy</a>
              </li>
            </ul>
          </div>

          {/* Services Section */}
          <div className="hidden md:block mb-5">
            <h1 className="flex mb-4 text-lg font-bold">Services</h1>
            <ul className="list-none p-0">
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Listing Services</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Liaison Services</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Directory Services</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Company Incorporation Services</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Import & Export Services</a>
              </li>
              <li className="mb-2 flex items-center">
                <i className="fas fa-angle-right mr-2"></i>
                <a href="#" className="text-white no-underline hover:underline">Documentation Services</a>
              </li>
            </ul>
          </div>

          {/* Follow Us Section */}
          <div className="mb-5">
            <h1 className="mb-4 text-lg font-bold">Follow Us</h1>
            <div className="flex justify-center items-center">
              <a href="#" className="text-white no-underline hover:underline mx-2">
                <i className="fab fa-linkedin fa-2x"></i>
              </a>
              <a href="#" className="text-white no-underline hover:underline mx-2">
                <i className="fab fa-instagram fa-2x"></i>
              </a>
              <a href="#" className="text-white no-underline hover:underline mx-2">
                <i className="fab fa-youtube fa-2x"></i>
              </a>
              <a href="#" className="text-white no-underline hover:underline mx-2">
                <i className="fab fa-facebook fa-2x"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Horizontal Divider */}
        <div className="border-t border-white my-5"></div>

        {/* Footer Copyright */}
        <p className="mx-2 text-sm
        ">Copyright © 2023 <span className="text-oceanBlue">ZeroMiddleman.com.</span> All Rights Reserved.</p>
      </footer>
    </div>
  );
};
