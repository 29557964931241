import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import CarouselFadeExample from './CarouselFadeExample'
import WhyChooseUs from './WhyChooseUs'
import { ExploreProducts } from './ExploreProducts'
import { CardCompon } from './CardCompon'
import { TrendingSection } from './TrendingSection'
import TellUsYourRequirements from './TellUsYourRequirements'
import Statistics from './Statistics'
import { Testimonial } from './Testimonial'
import ExploreService from './ExploreService'
import {ServiceCard} from './ServiceCard'
import Trendinng from './Trending'

 const MainHome = () => {
  return (
    <>
    <div className=''>
    <Header/>
    <CarouselFadeExample/>
    <WhyChooseUs/>
    <CardCompon/>
    <ExploreProducts/>
    <ServiceCard/>
    <ExploreService/>
    {/* <TrendingSection/> */}
    <Trendinng/>
    <TellUsYourRequirements/>
    <Statistics/>
    <Testimonial/>
    </div>
   

    {/* <Footer/> */}
    </>
  )
}
export default MainHome
