import React from 'react';
import CardImg1 from '../../../../../assetss/realTimeUpdate/cardsImgs/Group 3465201-1.png';
import CardImg2 from '../../../../../assetss/realTimeUpdate/cardsImgs/Group 3465201-2.png';
import CardImg3 from '../../../../../assetss/realTimeUpdate/cardsImgs/Group 3465201-3.png';
import CardImg4 from '../../../../../assetss/realTimeUpdate/cardsImgs/Group 3465201.png';
import './BenifitsOfRealTime.css';
const BenifitsOfRealTime = () => {
  return (
    <div>
        <div className='benifits-real-time flex justify-center'>
       <div className=" items-center lg:h-[500px] xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] mx-auto py-5 h-[1580px]">
            <div className="text-center">
                <h1 className="text-3xl text-white font-bold mb-6">Benefits of Real Time Updates</h1>
                <div className="flex flex-wrap lg:flex-nowrap justify-center lg:h-[400px] ">
                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2 mx-3 h-[350px]">
                        <div className="relative p-5  lg:w-[340px]   transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={CardImg1} alt="" className="w-[340px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[32px] left-[34px] w-[270px]  shadow-md  z-10 bg-white bg-opacity-75 py-4 px-2 min-h-[190px]  rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold text-md">Enhanced Visibility</h6>
                                <p>Gain complete visibility into your supply chain, enabling you to make informed decisions and manage expectation.</p>
                            </div>
                        </div>
                    </div>

                    {/* Repeat the above block for the other cards, just changing the text and images */}
                    
                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2 mx-3 h-[350px]">
                        <div className="relative p-5 lg:w-[340px]  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={CardImg2} alt="" className="w-[340px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[32px] left-[34px] w-[270px]  shadow-md z-10 bg-white bg-opacity-75 py-4 px-2  min-h-[190px]  rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold text-md">Improved communication</h6>
                                <p>Stay connected with our team, allowing for quick resolution on any issue that may arise.</p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2  mx-3 h-[350px]">
                        <div className="relative p-5 lg:w-[340px]  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={CardImg3} alt="" className="w-[340px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[32px] left-[34px] w-[270px]  shadow-md z-10 bg-white bg-opacity-75 py-4 px-2 min-h-[190px]  rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold text-md">Timely Notificatioons</h6>
                                <p>Receive alerts on significant events, such as delays or change s in delivery schedules, so you can plan accordingly.</p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:w-1/4 md:w-1/2 sm:w-full w-full flex justify-center p-2  mx-3 h-[350px]">
                        <div className="relative p-5 lg:w-[340px]  transition-transform duration-500 hover:scale-105">
                            <div className="flex justify-center">
                                <img src={CardImg4} alt="" className="w-[340px] h-[150px]" />
                            </div>
                            <div className="absolute top-[124px] lg:left-[32px] left-[34px] w-[270px]  shadow-md z-10 bg-white bg-opacity-75 py-4 px-2 min-h-[190px]  rounded-lg transition-colors duration-500 hover:bg-red-600 hover:text-white price-card">
                                <h6 className="font-bold text-md">Commitment to transparency</h6>
                                <p> Our commitment to providing real time update is part of our
                                dedication to delivering exceptional service and fostering long term partnerships.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default BenifitsOfRealTime
