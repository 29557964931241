import React from 'react';
import './LocalCoordinationBanner.css'


const LocalCoordinationBanner = () => {
    const bannerTitle="Co-ordination with Local Sources";
    const splitTitle = (title)=>{
        const midIndex = Math.ceil(title.length/2);
        const title1 = title.slice(0,midIndex);
        const title2 = title.slice(midIndex);
        return {title1,title2}
    }
    const {title1 ,title2} = splitTitle(bannerTitle);
    const localCoordinationBanner = [
        {
            title1:title1,
            title2:title2,
            description:"At ZMM, we understand that the success of our import export operation lingers on strong relationship with local sources. Our  commitment to fostering collaboration with local suppliers and partners allows us to deliver quality products efficiently and sustainably",
        }
    ]
  return (
    <div>
        <div className='flex justify-center local-co-banner'>
        <div className='w-[full] xl:max-w-[1400px] xl:w-[1400px] lg:w-[full] h-[469px] flex items-center justify-center '>
            {localCoordinationBanner.map((item,index)=>(
                <div key={index} className="banner-item flex flex-col items-center justify-center text-white p-8 rounded-lg shadow-lg lg:w-[1000px]">
                <h1 className="xl:text-3xl text-lg xl:font-bold font-semibold mb-4"><span className='text-red-600'>{item.title1}</span>{item.title2}</h1>
                <p className="xl:text-lg text-md leading-loose mb-4 text-center">{item.description}</p>
                
              </div>
            ))}
        </div>
    </div>
    </div>
  )
}

export default LocalCoordinationBanner
