import React from 'react'
import './QualityVerifictionProcess.css';
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaHandshakeSimple } from "react-icons/fa6";
import { TbTransform } from "react-icons/tb";
const QualityVerificationProcess = () => {
    const verificationBenifits = [
        {
            title:"Risk Mitigation",
            icon:<MdOutlineScreenSearchDesktop/>,
            description:"Minimize the risk of defective products and ensure compliance with international standards."
        },
        {
            title:"Cost Savings",
            icon:<FaHandHoldingUsd/>,
            description:"Avoid costly returns and rework by catching issues early in the process."
        },
        {
            title:"Enhanced Reputation",
            icon:<FaHandshakeSimple/>,
            description:"Minimize the risk of defective products and ensure compliance with international standards."
        },
        {
            title:"Streamlined Operations",
            icon:<TbTransform/>,
            description:"Minimize the risk of defective products and ensure compliance with international standards."
        }
    ]
  return (
    <div>
      <div className="quality-verification-process flex justify-center">
        <div className='xl:max-w-[1400px] lg:max-w-[1279px] xl:w-[full] lg:w-[full] lg:h-[450px] p-5 '>
            
            <h1 className='text-3xl font-bold text-center text-white'>Benifits For Our Quality Verification Services</h1>
            <div className='lg:h-[350px] flex items-center '>
            <div className='flex flex-wrap gap-[40px] lg:flex-nowrap justify-center  my-5 '>
                {verificationBenifits.map((cards,index)=>(
                     <div key={index} className='lg:w-1/4 md:w-1/2 sm:w-full w-full flex flex-col p-4 bg-slate-400  justify-center  shadow-2xl rounded-lg'>
                        <h1 className="text-xl font-semibold text-center p-3">{cards.title}</h1>
                        <div className='flex items-center justify-center'><span className='text-5xl'>{cards.icon}</span></div>
                        <p className='text-center'>{cards.description}</p>
                     </div>
                ))}
            </div>
            </div>
        </div>
      </div>
      </div>
  )
}
export default QualityVerificationProcess

