import React from 'react';
import './MappingServices.css'; // Ensure your CSS file is properly linked
import usaImg from '../../../../assetss/natinal flags/usa.png';
import brazilImg from '../../../../assetss/natinal flags/brazil.png';
import africaImg from '../../../../assetss/natinal flags/africa.png';
import indiaImg from '../../../../assetss/natinal flags/india.png';
import chinaImg from '../../../../assetss/natinal flags/china.png';
import afghanistanImg from '../../../../assetss/natinal flags/afghanistan.png';
import germanyImg from '../../../../assetss/natinal flags/germany.png';


const MappingServices = () => {
    return (
        <div className='xl:block hidden'>
        <div
            className="h-[609px] max-w-[1400px] mx-auto flex flex-col justify-center bg-cover bg-center bg-no-repeat mapping-services relative "
        >
                {/* Service Countries */}
                <div className="absolute top-[55px] left-[38px] service-country group">
                    <img src={usaImg} alt="USA" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>500+</h5>
                        <span>Services</span>
                    </div>
                </div>
                <div className="absolute top-[406px] left-[298px] service-country group">
                    <img src={brazilImg} alt="Brazil" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>200+</h5>
                        <span>Services</span>
                    </div>
                </div>
                <div className="absolute top-[301px] left-[701px] service-country group">
                    <img src={africaImg} alt="Africa" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>300+</h5>
                        <span>Services</span>
                    </div>
                </div>
                <div className="absolute top-[252px] left-[1054px] service-country group">
                    <img src={indiaImg} alt="India" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>600+</h5>
                        <span>Services</span>
                    </div>
                </div>
                <div className="absolute top-[109px] left-[1199px] service-country group">
                    <img src={chinaImg} alt="China" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>40+</h5>
                        <span>Services</span>
                    </div>
                </div>
                <div className="absolute top-[204px] left-[894px] service-country group">
                    <img src={afghanistanImg} alt="Afghanistan" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>100+</h5>
                        <span>Services</span>
                    </div>
                </div>
                <div className="absolute top-[4px] left-[636px] service-country group">
                    <img src={germanyImg} alt="Germany" className='rounded-full h-[60px] w-[60px]' />
                    <div className='country-details text-white text-center bg-black bg-opacity-50 w-[100px] h-[70px] rounded-lg flex flex-col justify-center items-center hidden group-hover:flex'>
                        <h5>400+</h5>
                        <span>Services</span>
                    </div>
                </div>
            </div>
            </div>
    );
}

export default MappingServices;
