import React from 'react'
import TrendingCategory from './TrendingCategory'
import TrendingService from './TrendingService'
import "./Trending.css"
const Trending = () => {
  return (
    <>
    <div className='trendingImage'>
        <TrendingCategory/>
        <TrendingService/>
    </div>
    </>
  )
}

export default Trending