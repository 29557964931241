import React from 'react';
import trustImg from '../../../../../assetss/afterSalesSupport/trustPartnerImg.png'

const TrustPartners = () => {
    return (
        <div>
            <div className="trust-partner flex justify-center">
                <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1269px] lg:w-[full] py-[20px]'>
                    <div className='container mx-auto'>
                    <div className='flex flex-wrap lg:flex-nowrap justify-center'>
                        <div className='lg:3/5 md:3/5 sm:w-full w-full flex items-center justify-center'>
                        <div className='lg:w-[600px]'>
                            <h1 className='text-2xl font-semibold py-2'>Your Trusted Partner beyond the Sale </h1>
                            <p>Our commitment to you doesn’t end with a successful transaction. We understand that robust after-sales support is essential for maintaining strong relationships and ensuring your business runs smoothly. Our dedicated team is here to assist you every step of the way, providing you with the support you need to maximize your investment and enhance customer satisfaction.</p>
                        </div>
                        </div>
                        <div className='lg:3/5 md:3/5 sm:w-full w-full flex items-center justify-center'>
                            <img src={trustImg} alt="" className='w-[full] h-[auto]'/>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrustPartners
