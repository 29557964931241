import React from 'react';
import cardImg1 from '../../../../../assetss/afterSalesSupport/cardImgs/Frame 427321467.png';
import cardImg2 from '../../../../../assetss/afterSalesSupport/cardImgs/Frame 427321467-1.png';
import cardImg3 from '../../../../../assetss/afterSalesSupport/cardImgs/Frame 427321467-2.png';
import cardImg4 from '../../../../../assetss/afterSalesSupport/cardImgs/Frame 427321467-3.png';
import cardImg5 from '../../../../../assetss/afterSalesSupport/cardImgs/Frame 427321467-4.png';
import cardImg6 from '../../../../../assetss/afterSalesSupport/cardImgs/Frame 427321467-5.png';

const SalesServices = () => {
  return (
    <div>
      <div className="sales-services flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-full lg:max-w-[1279px] lg:w-full'>
          <div className='container mx-auto'>
            <h1 className='text-3xl font-bold text-center mb-6'>Our After-Sales Support Services</h1>
            <div className='flex flex-wrap lg:flex-nowrap gap-[20px]'>
              {/* Card 1 */}
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full p-[20px]'>
                <div className='flex flex-col border border-[#0C92CC] rounded-lg min-h-[430px]'> {/* Set min height */}
                  <img src={cardImg1} alt="" className='w-full h-auto rounded-tl-lg rounded-tr-lg' />
                  <div className='p-3 flex-grow'> {/* Use flex-grow to ensure equal height distribution */}
                    <h1 className='text-center text-lg font-semibold'>Customer Service Hotline</h1>
                    <p className='text-sm'>Our dedicated customer service team is available to assist you with any questions or concerns. Reach out to us via phone, email, or chat for quick resolutions.</p>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full p-[20px]'>
                <div className='flex flex-col border border-[#0C92CC] rounded-lg min-h-[430px]'> {/* Set min height */}
                  <img src={cardImg2} alt="" className='w-full h-auto rounded-tl-lg rounded-tr-lg' />
                  <div className='p-3 flex-grow'>
                    <h1 className='text-center text-lg font-semibold'>Technical Support</h1>
                    <p className='text-sm'>Our knowledgeable technicians are here to help with any technical issues you may encounter. We provide:</p>
                    <ul className='text-sm custom-list list-disc ml-4'>
                      <li>Troubleshooting assistance</li>
                      <li>Installation guidance</li>
                      <li>Product training and demos</li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Card 3 */}
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full p-[20px]'>
                <div className='flex flex-col border border-[#0C92CC] rounded-lg min-h-[430px]'> {/* Set min height */}
                  <img src={cardImg3} alt="" className='w-full h-auto rounded-tl-lg rounded-tr-lg' />
                  <div className='p-3 flex-grow'>
                    <h1 className='text-center text-lg font-semibold'>Customer Service Hotline</h1>
                    <p className='text-sm'>Our dedicated customer service team is available to assist you with any questions or concerns. Reach out to us via phone, email, or chat for quick resolutions.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap lg:flex-nowrap gap-[20px]'>
              {/* Card 1 */}
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full p-[20px]'>
                <div className='flex flex-col border border-[#0C92CC] rounded-lg min-h-[430px]'> {/* Set min height */}
                  <img src={cardImg4} alt="" className='w-full h-auto rounded-tl-lg rounded-tr-lg' />
                  <div className='p-3 flex-grow'> {/* Use flex-grow to ensure equal height distribution */}
                    <h1 className='text-center text-lg font-semibold'>Warranty and Repair Services</h1>
                    <p className='text-sm'>We stand behind the quality of our products. If you encounter any issues covered under warranty, our support team will assist you with repairs or replacements.</p>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full p-[20px]'>
                <div className='flex flex-col border border-[#0C92CC] rounded-lg min-h-[430px]'> {/* Set min height */}
                  <img src={cardImg5} alt="" className='w-full h-auto rounded-tl-lg rounded-tr-lg' />
                  <div className='p-3 flex-grow'>
                    <h1 className='text-center text-lg font-semibold'>Feedback and Improvement</h1>
                    <p className='text-sm'>Your feedback is invaluable to us. We encourage you to share your thoughts on our products and services, allowing us to continuously improve and meet your evolving needs.</p>
                  </div>
                </div>
              </div>

              {/* Card 3 */}
              <div className='lg:w-1/3 md:w-1/2 sm:w-full w-full p-[20px]'>
                <div className='flex flex-col border border-[#0C92CC] rounded-lg min-h-[430px]'> {/* Set min height */}
                  <img src={cardImg6} alt="" className='w-full h-auto rounded-tl-lg rounded-tr-lg' />
                  <div className='p-3 flex-grow'>
                    <h1 className='text-center text-lg font-semibold'>Ongoing Communication</h1>
                    <p className='text-sm'>Stay informed with regular updates about product enhancements, new offerings, and tips to optimize your use of our products. We value your partnership and aim to keep the lines of communication open.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesServices;
