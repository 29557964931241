import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import MainHome from '../components/MainHome';
import ListingService from "../ListingServiceComponents/ListingService";
import LiaisionHome from "../pages/LiaisonServices/LiaisonHome/LiaisionHome";
import LocalCoordination from "../pages/LiaisonServices/localCoordination/LocalCoordination";
import DirectoryService from "../directoryService/DirectoryService";
import LiaisonService from '../pages/LiaisonServices/LiaisonService';
import QualityVerification from "../pages/LiaisonServices/QualityVerification/QualityVerification";
import DocumentationCertify from '../pages/LiaisonServices/Documentation&Certification/DocumentationCertify'
import RealTimeUpdate from "../pages/LiaisonServices/RealTimeUpdate/RealTimeUpdate";
import PaymentFollowUp from "../pages/LiaisonServices/PaymentFollowUp/PaymentFollowUp";
import AfterSalesSupport from "../pages/LiaisonServices/AfterSalesSupport/AfterSalesSupport";
import LocalNeeds from "../pages/LiaisonServices/LocalNeeds/LocalNeeds";

const router = createBrowserRouter([
    {
        path:'/',
        element:<App/>,
        children:[
            {
                path:'/',
                element:<MainHome/>
            },
            {
                path:'listing-service',
                element:<ListingService/>
            },
            {
                path:'liaision-service',
                element:<LiaisonService/>,
                children:[
                    {
                        path:'',
                        element:<LiaisionHome/>
                    },
                    {
                        path:'local-coordination',
                        element:<LocalCoordination/>
                    },
                    {
                        path:'quality-verification',
                        element:<QualityVerification/>
                    },
                    {
                        path:'documentation-certification',
                        element:<DocumentationCertify/>
                    },
                    {
                        path:'realtime-update',
                        element:<RealTimeUpdate/>
                    },
                    {
                        path:'payment-follow-up',
                        element:<PaymentFollowUp/>
                    },
                    {
                        path:'After-sales-support',
                        element:<AfterSalesSupport/>
                    },
                    {
                        path:'local-needs',
                        element:<LocalNeeds/>
                    }
                ]
            },
            {
                path:'directory-services',
                element:<DirectoryService/>
            }
        ]
    },
    
]);

export default router;