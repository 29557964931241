import React from 'react';

const products = [
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
  {
    imgSrc: require("../assetss/Component.jpg"),
  },
];

export const Partners = () => {
  return (
    <>
      <div className=" bg-white max-w-[1400px] mx-auto px-5 pt-5">
        <div className="text-center mb-8">
          <h4 className="text-blood text-3xl font-semibold">Our Trusted Partners</h4>
        </div>

        {/* For mobile: horizontal scrolling with 3-4 images visible initially */}
        <div className="flex overflow-x-auto space-x-4 md:grid md:grid-rows-2 md:grid-flow-col gap-y-24 mx-5 md:mx-2 justify-between">
          {products.map((product, index) => (
            <div
              className="flex-shrink-0 w-24 h-24 md:flex-none md:w-auto md:h-auto flex justify-center items-center"
              key={index}
            >
              <img
                src={product.imgSrc}
                alt={`Partner ${index}`}
                className="w-24 h-24 rounded-full object-cover border border-gray-300 p-1"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
