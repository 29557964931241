import React from 'react';
import vector1 from '../../../../../assetss/afterSalesSupport/vector1.png';
import icon1 from '../../../../../assetss/afterSalesSupport/icons/icon1.png';
import icon2 from '../../../../../assetss/afterSalesSupport/icons/icon2.png';
import icon3 from '../../../../../assetss/afterSalesSupport/icons/icon3.png';

const WhyAfterSales = () => {
    return (
        <div>
            <div className="why-after-sales flex justify-center">
                <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1269px] lg:w-[full]  p-[30px]'>
                    <div className='container mx-auto'>
                    <h1 className='text-center text-3xl font-bold '>Why After-Sales Support Matters</h1>
                    <div className='flex flex-wrap lg:flex-nowrap justify-center py-[20px]'>
                        <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full flex flex-col gap-[30px]'>
                            <h1 className='text-2xl font-semibold '>Effective after-sales support helps you:</h1>
                            <div className='flex'>
                                <div className='flex items-center p-3'>
                                    <img src={icon1} alt="" className='w-[40px] h-[40px]' />
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-xl font-semibold'>Resolve Issues Promptly:</h1>
                                    <p>Quick solutions to any problems that arise after delivery.</p>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='flex items-center p-3'>
                                    <img src={icon2} alt="" className='w-[40px] h-[40px]' />
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-xl font-semibold'>Enhance Customer Satisfaction:</h1>
                                    <p>Build trust and loyalty with your clients through responsive service</p>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className='flex items-center p-3'>
                                    <img src={icon3} alt="" className='w-[40px] h-[40px]' />
                                </div>
                                <div className='flex flex-col justify-center'>
                                    <h1 className='text-xl font-semibold'>Enhance Customer Satisfaction:</h1>
                                    <p>Ensure your team understands how to use and maximize the benefits of the products.</p>
                                </div>
                            </div>
                        </div>
                        <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full'>
                            <img src={vector1} alt="" className='w-[full] h-[auto]' />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyAfterSales
