import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const categories = [
  { title: "Renewable Energy", image: require("../assetss/Vector.png") },
  { title: "Sustainable Tech", image: require("../assetss/Vector.png") },
  { title: "Green Infrastructure", image: require("../assetss/Vector.png") },
  { title: "Eco Solutions", image: require("../assetss/Vector.png") },
  { title: "Solar Power", image: require("../assetss/Vector.png") },
  { title: "Wind Energy", image: require("../assetss/Vector.png") },
  { title: "Hydro Energy", image: require("../assetss/Vector.png") },
  { title: "Bio Energy", image: require("../assetss/Vector.png") },
  { title: "Recycling", image: require("../assetss/Vector.png") },
  { title: "Carbon Capture", image: require("../assetss/Vector.png") },
];

const TrendingCategory = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(5);

  useEffect(() => {
    const updateCardsToShow = () => {
      if (window.innerWidth < 768) {
        setCardsToShow(2);
      } else {
        setCardsToShow(5);
      }
    };

    // Initial setting based on screen size
    updateCardsToShow();

    // Listen to resize event
    window.addEventListener("resize", updateCardsToShow);
    return () => window.removeEventListener("resize", updateCardsToShow);
  }, []);

  const visibleCards = categories.slice(currentIndex, currentIndex + cardsToShow);

  const handleNext = () => {
    if (currentIndex + cardsToShow < categories.length) {
      setCurrentIndex(currentIndex + cardsToShow);
    }
  };

  const handlePrev = () => {
    if (currentIndex - cardsToShow >= 0) {
      setCurrentIndex(currentIndex - cardsToShow);
    }
  };

  return (
    <div className="relative p-4 md:py-4 md:px-2 max-w-[1400px] mx-auto ">
      <h1 className="text-white text-3xl font-semibold text-center">Trending Services</h1>

      <div className="flex items-center justify-between mt-6">
        {/* Left Arrow */}
        <button
          onClick={handlePrev}
          className={`text-white ${currentIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={currentIndex === 0}
        >
          <FaChevronLeft size={30} />
        </button>

        {/* Carousel of Cards */}
        <div className="flex space-x-4 overflow-hidden w-full justify-evenly">
          {visibleCards.map((category, index) => (
            <div
              key={index}
              className="bg-white border border-black rounded-full w-[120px] h-[120px] md:w-[150px] md:h-[150px] flex flex-col items-center justify-center m-2"
            >
              <img src={category.image} className="h-[40px] w-[40px] md:h-[50px] md:w-[50px]" alt={category.title} />
              <p className="text-[8px] md:text-[10px] text-center">{category.title}</p>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        <button
          onClick={handleNext}
          className={`text-white ${currentIndex + cardsToShow >= categories.length ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={currentIndex + cardsToShow >= categories.length}
        >
          <FaChevronRight size={30} />
        </button>
      </div>
    </div>
  );
};

export default TrendingCategory;
