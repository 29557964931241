import React from 'react';
import './DocumentationBanner.css'

const DocumentationBanner = () => {
  const bannerTitle = "Streamlining Your Import-Export Process";
    const splitTitle = (title) => {
        const midIndex = Math.ceil(title.length / 2);
        const title1 = title.slice(0, midIndex);
        const title2 = title.slice(midIndex);
        return { title1, title2 }
    }
    const { title1, title2 } = splitTitle(bannerTitle);
    const DocumentationBanner = [
        {
            title1: title1,
            title2: title2,
            description: "At ZMM, we recognize that efficient documentation and certification are crucial for successful international trade. Navigating the complexities of customs regulations, trade compliance, and documentation can be daunting, but our expert services simplify this process, ensuring your shipments move smoothly across borders.",
        }
    ]
  return (
    <div>
      <div className="documentation-banner flex justify-center">
        <div className='xl:max-w-[1400px] lg:max-w-[1279px] xl:w-[full] lg:w-[full] h-[469px]  lg:h-[469px] flex items-center'>

        {DocumentationBanner.map((banner, index) => (
                        <div key={index} className='flex flex-col items-center justify-center text-white py-3'>
                            <h1 className='lg:text-3xl text-xl text-center font-semibold'><span className='text-red-600 '>{banner.title1}</span>{banner.title2}</h1>
                            <span className='text-center leading-loose lg:px-[60px] py-3'>{banner.description}</span>
                        </div>
                    ))}

        </div>
      </div>
    </div>
  )
}

export default DocumentationBanner
