import React from "react";
import "./OurUniqueness.css";

const OurUniqueness = () => {
  return (
    <>
     <div className="flex justify-around bg-white max-w-[1400px] mx-auto px-5">
     <div className="">
        <div>
          <h1 className="text-blood text-center text-3xl font-semibold p-5">
            Our Uniqueness
          </h1>
        </div>
        <div className="px-1 flex flex-col lg:flex-row gap-10 justify-between">
          {/* First Column */}
          <div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[305px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/business-colleagues-banner-concept-with-copy-space 1.png")}
                alt="Business Colleagues"
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[480px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/medium-shot-people-working-together-office.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[305px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/creative-group-with-laptops-discussing-ideas-boardroom.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[480px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/diverse-executive-business-team-clapping-conference-room.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[355px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/people-office-having-meeting.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
          </div>
          
          {/* Second Column */}
          <div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[540px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/colleagues-talking-office.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center  p-3 rounded-2xl  ">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[510px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/people-analyzing-checking-finance-graphs-office.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[470px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/medium-shot-colleagues-discussing-work.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
           
            <div className="visibleWhenHover">
              <img
                className="w-full max-w-[1400px] h-[440px] my-5 rounded-xl"
                src={require("../assetss/ZmmDirectoryPage/managers-discussing-reports-with-boss-sitting-meeting-table-with-monitor-holding-documents-business-meeting-teamwork-concept.png")}
                alt=""
              />
              <h1 className="text-5xl text-white font-semibold">Our Data</h1>
              <p className="text-white text-center p-3 rounded-2xl">
                At Zmm, we take pride in offering a meticulously curated and extensive database...
              </p>
            </div>
          </div>
        </div>
      </div>
     </div>
    </>
  );
};

export default OurUniqueness;
