import React from 'react';
import vector2 from '../../../../../assetss/documentationCertification/vector2.png';
import vector3 from '../../../../../assetss/documentationCertification/vector3.png';
import './CertificationContent.css'

const CertificationContent = () => {
  return (
    <div>
      <div className="certification-content flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1200px] lg:w-[full] w-[full] xl:h-[1100px]'>
          <div className='flex flex-wrap gap-[40px] lg:flex-nowrap'>
            <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full p-[40px] relative'>

              <div className='certification-card text-white  lg:w-[450px] py-[20px]'>
                <h1 className='text-xl font-semibold'>Accurate Record-Keeping</h1>
                <p>We maintain meticulous records of all transactions, inspections, and certifications, ensuring transparency and  accountability.</p>
              </div>
              <div className='certification-card text-white  lg:w-[450px] py-[20px] lg:absolute lg:left-[200px]'>
                <h1 className='text-xl font-semibold'>Customs Documentation</h1>
                <p>We maintain meticulous records of all transactions, inspections, and certifications, ensuring transparency and  accountability.</p>
              </div>
              <div className='certification-card text-white  lg:w-[450px] py-[20px] lg:absolute  lg:top-[350px] z-40'>
                <h1 className='text-xl font-semibold'>Import/Export Licenses</h1>
                <p>We ensure that all required licenses are obtained and maintained, safeguarding your shipments against legal complications.</p>
              </div>
            </div>
            <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full'>
                <img src={vector2} alt="" className='w-[full] h-[auto]' />
            </div>
          </div>
          <div className='flex flex-wrap gap-[40px] lg:flex-nowrap'>
            <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full '>
            <img src={vector3} alt="" className='w-[full] h-[auto]' />
            </div>
            <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full  p-[40px] lg:relative'>
                <div className='certification-card text-white  lg:w-[450px] py-[20px] lg:absolute '>
                <h1 className='text-xl font-semibold'>Accurate Record-Keeping</h1>
                <p>We maintain meticulous records of all transactions, inspections, and certifications, ensuring transparency and  accountability.</p>
              </div>
              <div className='certification-card text-white  lg:w-[450px] py-[20px] lg:absolute lg:left-[150px] lg:top-[200px] '>
                <h1 className='text-xl font-semibold'>Customs Documentation</h1>
                <p>We maintain meticulous records of all transactions, inspections, and certifications, ensuring transparency and  accountability.</p>
              </div>
              <div className='certification-card text-white  lg:w-[450px] py-[20px] lg:absolute  lg:top-[380px]'>
                <h1 className='text-xl font-semibold'>Import/Export Licenses</h1>
                <p>We ensure that all required licenses are obtained and maintained, safeguarding your shipments against legal complications.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificationContent
