import React from 'react';
import vector1 from '../../../../../assetss/documentationCertification/documantationMatters.png';
import icon1 from '../../../../../assetss/documentationCertification/icons/Badge.png';
import icon2 from '../../../../../assetss/documentationCertification/icons/Certificate Check.png';
import icon3 from '../../../../../assetss/documentationCertification/icons/Customer.png';

const DocumentationMatters = () => {
  return (
    <div>
      <div className="documentation-matters flex justify-center">
        <div className='xl:max-w-[1400px] xl:w-[full] lg:max-w-[1279px] lg:w-[full] xl:h-[650px] lg:h-[650px] p-5'>
            <h1 className='text-center text-3xl font-bold'>Why Documentation & Certification Matters</h1>
            <div className='flex flex-wrap lg:gap-[40px] lg:flex-nowrap'>
                <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full'>
                <div className='lg:w-[600px] flex justify-center flex-col lg:relative'>
                    <div className="documentation-card flex flex-col  items-center  p-3 border border-[#0C92CC] rounded-lg my-5">
                        <img src={icon1} alt="" className='w-[40px] h-[40px]'/>
                        <h1 className='text-center  text-xl font-bold'>Customs Clearance</h1>
                        <p className='text-center'>Accurate documents expedite customs processing, reducing delays and penalties.</p>
                    </div>
                    <div className="documentation-card flex flex-col  items-center  p-3 border border-[#0C92CC] rounded-lg my-5 lg:absolute lg:left-[200px] lg:top-[15 0px]">
                        <img src={icon2} alt="" className='w-[40px] h-[40px]'/>
                        <h1 className='text-center  text-xl font-bold'>Regulatory Compliance</h1>
                        <p className='text-center'>Ensuring all necessary certifications meet international standards to avoid legal issues.</p>
                    </div>
                    <div className="documentation-card flex flex-col  items-center  p-3 border border-[#0C92CC] rounded-lg my-5 lg:absolute lg:top-[350px]">
                        <img src={icon3} alt="" className='w-[40px] h-[40px]'/>
                        <h1 className='text-center  text-xl font-bold'>Trust and Credibility</h1>
                        <p className='text-center'>Well-documented shipments enhance your credibility with partners and customers.</p>
                    </div>
                    </div>
                </div>
                <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full'>
                    <img src={vector1} alt="#"  className='h-[auto] w-[full]'/>
                </div>
            </div> 
        </div>
      </div>
    </div>
  )
}

export default DocumentationMatters
