import React from 'react'
import Header from '../SubComponents/Header/Header'
import RealTimeUpdateBanner from './SubComponents/RealtimeUpdateBanner/RealTimeUpdateBanner'
import StayConnected from './SubComponents/StayConnected/StayConnected'
import BenifitsOfRealTime from './SubComponents/BenifitsOfRealTime/BenifitsOfRealTime'

const RealTimeUpdate = () => {
  return (
    <div>
    
      <RealTimeUpdateBanner/>
      <StayConnected/>
      <BenifitsOfRealTime/>
    </div>
  )
}

export default RealTimeUpdate
