import React from 'react';
import vector4 from '../../../../../assetss/documentationCertification/vector4.png';
import './CertificateExcellence.css'


const CertificateExcellence = () => {
  return (
    <div>
      <div className="certificate-excellence flex justify-center">
        <div className='xl:max-w-[1400px] lg:max-w-[1200px] xl:w-[full] lg:w-[full] py-[20px]'>
        <div className='flex flex-wrap gap-[40px] lg:flex-nowrap'>
        <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full flex items-center justify-center '>
        <div className='excellence-card w-[320px] lg:w-[400px]'>
          <h1 className='text-2xl font-bold'>Commitment to Excellence</h1>
          <p>
          Our dedication to quality verification, thorough documentation, and proper certification not only enhances the trust of our clients but also strengthens our reputation in the global market.
          </p>
          </div>
        </div>
        <div className='lg:w-1/2 md:w-1/2 sm:w-full w-full '>
          <img src={vector4} alt="" className='w-[full] h-[auto]'/>
        </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CertificateExcellence
