import React from "react";

const Statistics = () => {
  const stats = [
    { value: "200M+", label: "Products" },
    { value: "200k+", label: "Suppliers" },
    { value: "200+", label: "Buyers" },
    { value: "100+", label: "Company Setup" },
  ];

  return (
    <div>
      <div
        className="relative z-0 w-full  h-auto lg:h-[362px] flex flex-col justify-center text-center bg-none lg:bg-[url('./assetss/statistic1.png')] lg:bg-cover lg:bg-no-repeat lg:bg-blend-multiply mb-5"
      >
        {/* Banner content */}
        <h1 className="text-black font-semibold text-2xl lg:text-3xl lg:text-white lg:absolute top-10 lg:left-1/2 lg:transform lg:-translate-x-1/2">
        Our Statistics
  </h1>
        <h5 className="text-black font-bold text-lg max-w-[1400px] mx-auto lg:text-white lg:text-2xl">
          We're dedicated to providing an exceptional experience for our users.
        </h5>f
        <p className="px-5 text-black text-sm mt-3 max-w-[1400px] mx-auto lg:text-white lg:text-[18px] leading-7  lg:px-10">
          Below, you’ll find some key statistics that highlight the growth and
          diversity of our offerings. From a wide range of suppliers to a robust
          catalog of products and categories, these figures reflect our
          commitment to connecting you with the resources you need. Let’s take a
          closer look at our achievements!
        </p>
      </div>

      {/* Cards Section */}
      <div className="relative z-10 my-8   mx-4 max-w-[1400px] sm:mx-auto mt-5 lg:mt-[-130px]  grid grid-cols-1 gap-4 lg:gap-0 lg:grid-cols-4">
        {stats.map((stat, index) => (
          <div
            key={index}
            className="bg-[#0C064E]  m-3  h-40 lg:h-56 rounded-[20px] shadow-lg flex flex-col items-center justify-center"
          >
            <h1 className="text-3xl lg:text-4xl text-center text-white">
              {stat.value}
            </h1>
            <h1 className="text-xl lg:text-3xl text-center text-white whitespace-nowrap">
              {stat.label}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
