import React from 'react';
import { PiMedalBold } from "react-icons/pi";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { SiMarketo } from "react-icons/si";
import { FaSearchLocation } from "react-icons/fa";

const WhyLocalCoordination = () => {
    const whyMattersCards = [
        {
            icon:<PiMedalBold />,
            title:"Quality Assurance",
            description:"Partnering with local sources enables us to maintain high standards and ensure that every products meets our specifications."
        },
        {
            icon:<FaHandHoldingDollar />,
            title:"Cost Efficiency",
            description:"By sources locally, we reduce shipping cost and transit Ɵme allowing us to offer compeƟƟ ve pricing."
        },
        {
            icon:<SiMarketo/>,
            title:"Cultural  Understanding",
            description:"Our local partners provide valuable insights into regional markets, helping us navigate cultural nuances and customer preferences"
        },
        {
            icon:<FaSearchLocation/>,
            title:"Sustainability",
            description:"Collaborating with local suppliers reduces our carbon footprints and supports local economics, aligning with our commitment to sustainable practices."
        }
    ]
  return (
    <div className='flex justify-center items-center'>
      <div className='max-w-[1400px] my-[40px] '>
        <div>
            <h1 className='text-center font-semibold text-lg xl:text-3xl p-3'>Why Local Co-ordination Matters</h1>
            <div >
                <div className='flex flex-wrap lg:flex-nowrap gap-7 justify-center'>
                    {whyMattersCards.map((cards,index)=>(
                        <div key={index} className='lg:w-1/4 md:w-1/2 sm:w-full w-full flex  flex-col items-center justify-center border border-[#69BCDF] p-3 mx-3 rounded-lg'>
                            <div className='h-[100px] flex items-center py-2 my-2'>
                                 <span className='text-5xl py-3 text-[#69BCDF]'>{cards.icon}</span>
                            </div>
                            <div className='p-2'>
                            <h1 className='text-xl text-center text-[#69BCDF] font-semibold'>{cards.title}</h1>
                            <p className='text-center'>{cards.description}</p>
                            </div>
                            </div>
                    ))}
                </div>
            </div>

        </div>
      </div>
    </div>
  )
}

export default WhyLocalCoordination
