import React from "react";
import { FaQuestionCircle, FaEnvelope, FaHandshake, FaLongArrowAltRight } from 'react-icons/fa';
import requirementsImage from '../assetss/requirements.png'; 
import TellUsYourRequirement from '../assetss/TellUsYourRequirement/question.gif'; 
import TellUsYourMessage from '../assetss/TellUsYourRequirement/message.gif'; 
import TellUsYourDeal from '../assetss/TellUsYourRequirement/handshakes.gif'; 
const TellUsYourRequirements = () => {
  return (
    <div className=" max-w-[1400px] bg-white mx-auto">
      <div className="  px-2  text-center">
        <h1 className="text-lg lg:text-3xl pt-10 font-semibold">Tell us your Requirements</h1>
      </div>

      <div className="items-center p-5 lg:flex lg:items-center lg:justify-between lg:py-10">
        {/* Left side: Image */}
        <div className="hidden lg:block">
          <img
            src={requirementsImage}
            alt="Requirements"
            className="w-full max-w-lg"
          />
        </div>

        {/* Right side: Text */}
        <div className="flex-1 flex flex-col justify-center lg:pl-10">
          <h1 className="text-3xl font-bold mb-4 text-center lg:text-left">
            Get your Quotes from multiple sellers.
          </h1>
          <p className="text-lg mb-6 text-center lg:text-left">
            It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged.
          </p>

          <div className="flex flex-row space-x-6 items-center justify-between">
  <div className="flex flex-col items-center text-center">
  <img src={TellUsYourRequirement} alt="" className="w-1/4 h-1/4" />
  <span>Tell us what you need</span>
  </div>
  <div className="flex flex-col items-center text-center">
  <img src={TellUsYourMessage} alt="" className="w-1/4 h-1/4" />
    <span>Receive free quotes from sellers</span>
  </div>
  <div className="flex flex-col items-center text-center">
  <img src={TellUsYourDeal} alt="" className="w-1/4 h-1/4" />
    <span>Seal your deal</span>
  </div>
</div>

          <div className="flex justify-center lg:justify-end mt-5">
            <button className="text-white bg-red-500 border rounded-lg px-6 py-2 flex items-center gap-2">
              Type your Requirements
              <span className="text-white">
                <FaLongArrowAltRight />
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile view */}
      {/* <div className="flex flex-col space-y-6 lg:hidden px-4">
        <h1 className="text-3xl font-bold text-center">Tell us your requirements</h1>
        <h1 className="text-3xl font-bold text-center">Get your Quotes from multiple sellers.</h1>
        <p className="text-lg text-center">
          It has survived not only five centuries, but also the leap into
          electronic typesetting, remaining essentially unchanged.
        </p>
        <div className="flex flex-col space-y-4 items-center">
          <div className="flex flex-col items-center">
            <FaQuestionCircle className="text-4xl text-blue-500 mb-2" />
            <span>Tell us what you need</span>
          </div>
          <div className="flex flex-col items-center">
            <FaEnvelope className="text-4xl text-blue-500 mb-2" />
            <span>Receive free quotes from sellers</span>
          </div>
          <div className="flex flex-col items-center">
            <FaHandshake className="text-4xl text-blue-500 mb-2" />
            <span>Seal your deal</span>
          </div>
        </div>

        <div className="flex justify-center mt-5">
          <button className="text-white bg-red-500 border rounded-lg px-6 py-2 flex items-center gap-2">
            Type your Requirements
            <span className="text-black">
              <FaLongArrowAltRight />
            </span>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default TellUsYourRequirements;
